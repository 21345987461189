import { Component } from 'preact';
import ClassNames from "classnames";
import TextWrapper from "../../util/wrapper/TextWrapper";
import OpeningHoursContainer from "./OpeningHoursContainer";
import { generateId } from "../../util/AppUtils";

export default class OpeningHoursTooltipLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipId: generateId("tooltip"),
            visible: false
        };
    }

    onShowOpeningHours = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({visible: true});
        document.addEventListener('click', this.onHideOpeningHours);
    };

    onHideOpeningHours = () => {
        this.setState({visible: false});
        document.removeEventListener('click', this.onHideOpeningHours);
    };

    render() {
        const {tooltipId, visible} = this.state;
        const {openingTimes} = this.props;

        return (
            <li className="point__options__item">
                <button type="button"
                        className="point__options__link link link--info"
                        aria-describedby={tooltipId}
                        onClick={this.onShowOpeningHours}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h20v20H0z"/>
                            <path className="colorable" fill="#000"
                                  d="M10 0C4.478 0 0 4.477 0 10s4.477 10 10 10c5.522 0 10-4.477 10-10S15.522 0 10 0zm.934 3.61c.975 0 1.261.566 1.261 1.213 0 .807-.646 1.554-1.749 1.554-.923 0-1.362-.464-1.335-1.231 0-.647.54-1.536 1.823-1.536zM8.436 15.99c-.667 0-1.153-.405-.687-2.181l.763-3.151c.132-.504.154-.706 0-.706-.199 0-1.064.347-1.575.691l-.332-.545c1.62-1.353 3.482-2.146 4.279-2.146.666 0 .777.787.445 2l-.875 3.312c-.156.585-.089.787.066.787.2 0 .854-.241 1.498-.749l.377.507c-1.576 1.575-3.293 2.18-3.959 2.18z"/>
                        </g>
                    </svg>

                    <TextWrapper id="pickupLocations.openingHours"/>
                </button>

                <aside role="tooltip"
                       id={tooltipId}
                       className={ClassNames(
                           "widget-tooltip widget-tooltip--hours tooltip--hours",
                           {"widget-tooltip--focused": visible}
                       )}>

                    <OpeningHoursContainer openingTimes={openingTimes}/>
                </aside>
            </li>
        );
    }
}