import { Component } from 'preact';
import TypeNavigation from "./TypeNavigation";
import { DELIVERY_TYPES, STORAGE_DELIVERY_TYPE_KEY } from "../../constants/constants";
import DateGroupsContainer from "./DateGroupsContainer";
import ClassNames from "classnames";
import RawDateContainer from "../raw/RawDateContainer";

export default class NominatedDateContainer extends Component {

    constructor(props) {
        super(props);

        const {config} = props;

        let selectedType = config.previousSelection && config.previousSelection.previousDeliveryType ?
            config.previousSelection.previousDeliveryType :
            DELIVERY_TYPES.ESTIMATED_DATE;

        this.state = {
            changedManually: false,
            selectedType
        };
    }

    onSelectType = (selectedType) => {
        localStorage.setItem(STORAGE_DELIVERY_TYPE_KEY, selectedType);

        this.setState({
            selectedType,
            changedManually: true
        });
    };

    getExactDateOptions = () => {
        const {options} = this.props;

        return options && options.filter((o) => {
            return o.deliveryDates && o.deliveryDates.length;
        });
    };

    getEstimatedDateOptions = () => {
        const {options} = this.props;

        return options && options.filter((o) => {
            return !o.deliveryDates || !o.deliveryDates.length;
        });
    };

    switchToVisibleType = (hasExactDateOptions, hasEstimatedDateOptions) => {
        const {selectedType} = this.state;

        if (!hasExactDateOptions && !hasEstimatedDateOptions) {
            return;
        }

        if (!hasExactDateOptions && selectedType === DELIVERY_TYPES.EXACT_DATE) {
            this.onSelectType(DELIVERY_TYPES.ESTIMATED_DATE);
        }

        if (!hasEstimatedDateOptions && selectedType === DELIVERY_TYPES.ESTIMATED_DATE) {
            this.onSelectType(DELIVERY_TYPES.EXACT_DATE);
        }
    };

    getEstimatedDateTab = (options) => {
        const {selectedType} = this.state;
        const {onSelectOption, config} = this.props;

        const isSelected = DELIVERY_TYPES.ESTIMATED_DATE === selectedType;

        const estimatedDateClassName = ClassNames(
            "tabs__panels__panel type-select__panel panel",
            {"panel--selected": isSelected}
        );

        return (
            <div className={estimatedDateClassName}
                 id="type-picker__panel--0"
                 aria-labelledby="type-picker__btn--0"
                 aria-hidden={!isSelected}>
                <div className="type-select__tab-content">
                    {
                        isSelected &&
                        <RawDateContainer options={options}
                                          onSelectOption={onSelectOption}
                                          config={config}/>
                    }
                </div>
            </div>
        );
    };

    getExactDateTab = (options) => {
        const {selectedType, changedManually} = this.state;
        const {onSelectOption, currency, deliveryRangeFormat, isPricingEnabled, isShowAsExtraCost, config} = this.props;

        const isSelected = DELIVERY_TYPES.EXACT_DATE === selectedType;

        const exactDateClassName = ClassNames(
            "tabs__panels__panel type-select__panel panel",
            {"panel--selected": isSelected}
        );

        return (
            <div className={exactDateClassName}
                 id="type-picker__panel--1"
                 aria-labelledby="type-picker__btn--1"
                 aria-hidden={!isSelected}>
                <div className="type-select__tab-content">
                    {
                        isSelected &&
                        <DateGroupsContainer options={options}
                                             isPricingEnabled={isPricingEnabled}
                                             isShowAsExtraCost={isShowAsExtraCost}
                                             currency={currency}
                                             onSelectOption={onSelectOption}
                                             deliveryRangeFormat={deliveryRangeFormat}
                                             config={config}
                                             changedManually={changedManually}
                        />
                    }
                </div>
            </div>
        );
    };

    render() {
        const {selectedType} = this.state;
        const {config} = this.props;

        const estimatedDateOptions = this.getEstimatedDateOptions();
        const exactDateOptions = this.getExactDateOptions();

        const hasEstimatedDateOptions = estimatedDateOptions && estimatedDateOptions.length > 0;
        const hasExactDateOptions = exactDateOptions && exactDateOptions.length > 0;

        this.switchToVisibleType(hasExactDateOptions, hasEstimatedDateOptions);

        return (
            <section className="body__tabs tabs type-select" id="type-picker">

                <TypeNavigation selectedType={selectedType}
                                config={config}
                                onSelectType={this.onSelectType}
                                estimatedDateOptions={estimatedDateOptions}
                                exactDateOptions={exactDateOptions}/>


                <div className="tabs__panels type-select__panels">
                    {this.getEstimatedDateTab(estimatedDateOptions)}
                    {this.getExactDateTab(exactDateOptions)}
                </div>
            </section>
        );
    }
}