import { Component } from 'preact';
import ClassNames from "classnames";
import SelectDayDropdown from "./SelectDayDropdown";
import SelectDayCarousel from "./SelectDayCarousel";
import RawDateContainer from "../raw/RawDateContainer";
import TextWrapper from "../../util/wrapper/TextWrapper";
import { format, parse } from "../../util/DateTimeUtils";
import { FORMAT_MASKS } from "../../constants/constants";

export default class DateGroupsContainer extends Component {

    constructor(props) {
        super(props);
        const {config, changedManually} = props;

        const groups = this.groupOptionsByDate();
        const activeGroup = !changedManually && config.previousSelection && config.previousSelection.previousPreferredDate ?
            this.findPreviousGroupIndex(groups) :
            this.calculateInitiallySelectedGroupIndex(config, groups);

        this.state = {
            activeGroup
        };
    }

    onSelectGroup = (activeGroup) => {
        this.setState({
            activeGroup
        });
    };

    groupOptionsByDate = () => {
        const {options} = this.props;

        let groups = [];

        options && options.forEach((o, i) => {
            o.deliveryDates && o.deliveryDates.forEach(d => {

                let deliveryDate = parse(d.deliveryDate);
                let index = groups.findIndex(g => g.date.toDateString() === deliveryDate.toDateString());

                if (index === -1) {
                    groups.push({
                        date: deliveryDate,
                        groupOptions: [{...o, timeRange: d, optionOrder: i}]
                    });
                } else {
                    let optionIndex = groups[index].groupOptions.findIndex(go => go.identifier === o.identifier);

                    if (optionIndex === -1) {
                        groups[index].groupOptions.push({...o, timeRange: d, optionOrder: i});
                    }
                }

            });
        });


        groups.sort((a, b) => a.date.getTime() - b.date.getTime());

        return groups;
    };

    findPreviousGroupIndex = (groups) => {
        const {config} = this.props;

        const previousIndex = groups.findIndex(g => format(g.date, FORMAT_MASKS.DEFAULT_DATE, true) === config.previousSelection.previousPreferredDate);

        return previousIndex !== -1 ? previousIndex : this.calculateInitiallySelectedGroupIndex(config, groups);
    };

    calculateInitiallySelectedGroupIndex = (config, groups) => {
        const {sortingModel} = config;

        if (sortingModel && sortingModel.orderBy === "DATE" && sortingModel.sortOrder === "DESC") {
            const index = this.findGroupWithFirstOption(groups.slice().reverse());
            const count = groups.length - 1;
            return index >= 0 ? count - index : index;
        }

        return this.findGroupWithFirstOption(groups);
    };

    findGroupWithFirstOption = (groups) => {
        return groups.findIndex(g => g.groupOptions.find(go => go.optionOrder === 0));
    };

    render() {
        const {activeGroup} = this.state;
        const {onSelectOption, config} = this.props;

        const groups = this.groupOptionsByDate();

        return (
            groups && groups.length > 0 ?
                <section className="body__tabs tabs day-select" id="day-picker">

                    <SelectDayDropdown optionGroups={groups}
                                       activeGroup={activeGroup}
                                       onSelectGroup={this.onSelectGroup}/>

                    <SelectDayCarousel optionGroups={groups}
                                       activeGroup={activeGroup}
                                       onSelectGroup={this.onSelectGroup}/>

                    <div className="tabs__panels day-select__panels">

                        {
                            groups.map((group, index) => {
                                let isSelected = index === activeGroup;
                                let groupClassName = ClassNames("tabs__panels__panel day-select__panel panel", {"panel--selected": isSelected});

                                return (
                                    <div className={groupClassName}
                                         id={"day-picker__panel--" + index}
                                         aria-labelledby={"day-picker__btn--" + index}
                                         aria-hidden={!isSelected}>
                                        <div className="day-select__tab-content">
                                            {
                                                isSelected &&
                                                <RawDateContainer options={group.groupOptions}
                                                                  exactDate={group.date}
                                                                  config={config}
                                                                  onSelectOption={onSelectOption}/>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }

                    </div>

                </section> :

                <TextWrapper id="shippingOptions.optionsNotFound"/>

        );
    }
}