import {Component} from 'preact';
import {CARRIER_LOGO_URL} from "../../constants/constants";
import {doGet} from "../../util/NetworkUtils";

export default class CarrierLogoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageAvailable: true,
            carrierLogoImage: ""
        };
    }

    componentDidMount() {
        this.loadCarrierLogo();
    }

    handleImageError = () => {
        this.setState({
            imageAvailable: false
        });
    };

    loadCarrierLogo = () => {
        const {config, shippingOption} = this.props;

        let logoUrl = CARRIER_LOGO_URL + shippingOption.carrier.name + ".svg";

        doGet(logoUrl, config)
            .then(res => {
                this.setState({
                    carrierLogoImage: !res || !res.data ? "" : `data:image/svg+xml;base64,${res.data}`,
                    imageAvailable: true
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    carrierLogoImage: ""
                });
            });
    };

    render() {
        const {shippingOption} = this.props;
        const {imageAvailable, carrierLogoImage} = this.state;
        return (
            <section>
                {
                    imageAvailable &&
                    <figure className="point__image">
                        <img src={carrierLogoImage}
                         className="point__image__source"
                         onError={this.handleImageError}
                         title={shippingOption.carrier.description}
                         alt={shippingOption.carrier.description}
                    />
                </figure>
                }
            </section>
        );
    }
}