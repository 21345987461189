import { Component } from 'preact';
import ClassNames from "classnames";
import TextWrapper from "../../../components/util/wrapper/TextWrapper";
import {getPickupType} from "../../util/AppUtils";

export default class DifferentLocationButton extends Component {

    constructor(props) {
        super(props);
    }

    openModal = (e) => {
        e.preventDefault();
        this.props.toggleModal(true);
    };

    render() {
        const {isStorePickup, isParcelLocker} = this.props;

        const selectDifferentButtonClassName = ClassNames(
            {"pickup-select__button": !isStorePickup},
            {"store-select__button": isStorePickup},
            "button button--primary button--location"
        );

        const pickupType = getPickupType(isStorePickup, isParcelLocker);

        return (
            <button className={selectDifferentButtonClassName}
                    id={isStorePickup ? "open-store-select" : "open-pickup-select"}
                    onClick={this.openModal}>

                    <TextWrapper id={"pickupLocations." + pickupType + ".selectDifferent"}/>

            </button>
        );
    }
}