import { Component } from 'preact';
import ClassNames from "classnames";
import FooterForm from "./form/FooterForm";
import NominatedDateContainer from "./nominated/NominatedDateContainer";
import RawDateContainer from "./raw/RawDateContainer";
import { getDeliveryConfig, isDeliveryConfigChanged } from "../util/ConfigUtils";
import { doPost } from "../util/NetworkUtils";
import { OPTION_LIST_URL, TABS } from "../constants/constants";
import TitleForm from "./form/TitleForm";
import { checkout } from "../service/restApiService";
import Loader from "../common/Loader";
import { findMinPrice } from "../util/CurrencyUtils";
import { findMinDate } from "../util/DatesEstimationUtils";
import { logRequestError, logRequestPerformed } from "../util/Logger";

export default class DeliveryContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            fullDeliveryDateSelection: false,
            changedManually: false,
            loading: true,
            initializedOn: props.config.initializedOn
        };
    }

    componentDidMount = () => {
        this.fetchOptions(this.props.config);
    };

    componentDidUpdate(previousProps) {
        const {config, tabActive} = this.props;
        const {options, loading} = this.state;

        if (isDeliveryConfigChanged(previousProps.config, config)) {
            this.fetchOptions(config);
        } else if (config.initializedOn !== previousProps.config.initializedOn) {
            this.setState({initializedOn: config.initializedOn});
        }

        if (!previousProps.tabActive && tabActive) {
            const option = options.find(e => e.checked);

            if (option && !loading) {
                checkout(this.props.config, option.identifier, null, option.exactDay);
            }
        }
    }

    fetchOptions = (config) => {
        if (!config.consigneeCountryCode) {
            return;
        }

        const {loadPaazlBasedData, loadCarrierBasedData} = config;
        const requestConfiguration = getDeliveryConfig(config);

        this.setOptions([], false, true);

        if (loadPaazlBasedData) {
            let localRequestConfig = {...requestConfiguration, includeExternalDeliveryDates: false};

            doPost(OPTION_LIST_URL, localRequestConfig)
                .then(res => {
                    logRequestPerformed(config, OPTION_LIST_URL, localRequestConfig);
                    this.setOptions(res.data && res.data.shippingOptions, res.data && res.data.fullDeliveryDateSelection, loadCarrierBasedData);

                    if (loadCarrierBasedData) {
                        this.loadCarrierBasedOptions(config, requestConfiguration);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    logRequestError(config, e, OPTION_LIST_URL, localRequestConfig);
                    this.setOptions([]);
                });
        }

        if (loadCarrierBasedData && !loadPaazlBasedData) {
            this.loadCarrierBasedOptions(config, requestConfiguration);
        }
    };

    loadCarrierBasedOptions = (config, requestConfiguration) => {
        let requestConfig = {...requestConfiguration, includeExternalDeliveryDates: true};

        doPost(OPTION_LIST_URL, requestConfig)
            .then(res => {
                logRequestPerformed(config, OPTION_LIST_URL, requestConfig);
                this.setOptions(res.data && res.data.shippingOptions, res.data && res.data.fullDeliveryDateSelection);
            })
            .catch((e) => {
                console.error(e);
                logRequestError(config, e, OPTION_LIST_URL, requestConfig);
                this.setOptions([]);
            });
    };

    setOptions = (options = [], fullDeliveryDateSelection = false, loading = false) => {
        const {previousSelectionApplied: oldPreviousSelectionApplied} = this.state;
        const {onTabLoaded, config} = this.props;

        const isEmpty = options.length === 0;

        let previousSelectionApplied = oldPreviousSelectionApplied;

        if (!loading && !isEmpty && !previousSelectionApplied && config.previousSelection && config.previousSelection.previousOption) {
            previousSelectionApplied = true;
            options = [...options].map(o => {
                return {...o, checked: o.identifier === config.previousSelection.previousOption};
            });
        }

        this.setState({options, fullDeliveryDateSelection, loading, previousSelectionApplied});

        const price = !isEmpty && findMinPrice([...options]);
        const date = !isEmpty && findMinDate([...options], config.deliveryRangeFormat);

        onTabLoaded({
            tab: TABS.DELIVERY,
            isEmpty,
            price,
            date
        });
    };

    onSelectOption = (identifier, preferredDeliveryDate) => {
        const {tabActive, config} = this.props;
        const {options, loading} = this.state;

        if (tabActive && !loading) {
            checkout(config, identifier, null, preferredDeliveryDate);

            this.setState({
                ...this.state,
                options: options.map(o => {
                    return {...o, checked: o.identifier === identifier, exactDay: preferredDeliveryDate};
                })
            });
        }
    };

    getSelectedOption = () => {
        const {options} = this.state;

        return options && options.find(o => o.checked);
    };

    getContainer = () => {
        const {config} = this.props;
        const {options, fullDeliveryDateSelection} = this.state;

        if (config.nominatedDateEnabled && fullDeliveryDateSelection) {
            return <NominatedDateContainer options={options}
                                           onSelectOption={this.onSelectOption}
                                           config = {config}/>;
        }

        return <RawDateContainer options={options && options.slice(0, config.shippingOptionsLimit)}
                                 onSelectOption={this.onSelectOption}
                                 config={config}/>;
    };

    render() {
        const {tabActive, config} = this.props;
        const {loading, initializedOn} = this.state;

        const panelClassName = ClassNames(
            "body__panel panel method-select__panel",
            "initialized-" + initializedOn,
            {"panel--selected": tabActive}
        );

        return (
            <div id="tab-panel-delivery"
                 className={panelClassName}
                 aria-labelledby="tab-button-delivery"
                 aria-hidden={!tabActive}>

                <div className="method-select__tab-content">
                    <Loader isLoading={loading}/>

                    {!loading && <TitleForm/>}
                    {!loading && this.getContainer()}
                    {
                        !loading &&
                        <FooterForm selectedOption={this.getSelectedOption()}
                                    config={config}
                        />
                    }
                </div>
            </div>
        );
    }
}
