import {getCurrentTranslation} from "../../i18n/I18nResolver";


export const getDistanceUnit = (distance) => {
    const tranlations = getCurrentTranslation();
    return distance >= 1000 ?  tranlations.common.kilometers : tranlations.common.meters;
};

export const convertDistance = (distanceInMeters, decimal) => {
    if (distanceInMeters < 1000) {
        return distanceInMeters;
    }
    return ((Math.round(distanceInMeters * 1000) / 1000) / 1000).toFixed(decimal);
};

export const formatDistance = (distance) => {
    const formattedDistance = convertDistance(distance, 1);
    const distanceUnit = getDistanceUnit(distance);
    const unknownDistance = getCurrentTranslation().pickupLocations.distance;

    if (!isNaN(formattedDistance)) {
        return `${formattedDistance} ${distanceUnit}`;
    }

    return unknownDistance;
};