import {Component} from 'preact';
import PickupElement from "./PickupElement";
import DifferentLocationButton from "../../pickup/list/DifferentLocationButton";
import ItemsNotFoundForm from "../../common/ItemsNotFoundForm";
import {TABS} from "../../constants/constants";

export default class PickupListContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {config, pickupLocations, onSelectPickupLocation, toggleModal, toggleSimpleMapModal, isStorePickup, isParcelLocker, storeLogo, accountNumberWithState} = this.props;

        let currentTab = TABS.PICKUP;
        if (isStorePickup) {
            currentTab = TABS.STORE;
        } else if (isParcelLocker) {
            currentTab = TABS.PARCEL_LOCKER;
        }

        const sectionClassName = isStorePickup ? "store-select" : "pickup-select";
        const listClassName = isStorePickup ? "store-select__listing" : "pickup-select__listing";

        const hasItems = pickupLocations && pickupLocations.length > 0;

        return (
            <section className={sectionClassName}>
                {!hasItems && <ItemsNotFoundForm tab={currentTab}/>}

                <ul className={listClassName} aria-labelledby="pickup-tab-title">
                    {
                        hasItems &&
                        pickupLocations.map(p =>
                            <PickupElement config={config}
                                           isStorePickup={isStorePickup}
                                           storeLogo={storeLogo}
                                           accountNumberWithState={accountNumberWithState}
                                           pickupLocation={p}
                                           onSelectPickupLocation={onSelectPickupLocation}
                                           toggleSimpleMapModal={toggleSimpleMapModal}/>
                        )
                    }
                </ul>

                <DifferentLocationButton toggleModal={toggleModal}
                                         isStorePickup={isStorePickup}
                                         isParcelLocker={isParcelLocker}/>

            </section>
        );
    }
}