import { Component } from 'preact';
import '../scss/index.scss';

import { getDefaultTab } from "./util/ConfigUtils";
import { checkBrowser } from "./util/AppUtils";
import { logWidgetLoaded } from "./util/Logger";

import Header from "./header/Header";
import StyleContainer from "./style/StyleContainer";
import BodyContainer from "./BodyContainer";

import cssVars from "css-vars-ponyfill";

import { DEFAULT_CONFIG, STORAGE_TAB_KEY } from "./constants/constants";

export default class App extends Component {

    static defaultProps = {
        config: {}
    };

    constructor(props) {
        super(props);

        const {config} = props;
        const selectedTab = config.defaultTab || getDefaultTab(config);

        this.state = {
            selectedTab,
            changedManually: false,
            tabDetails: {},
            visibleTabs: [],
            hiddenTabs: []
        };
    }

    componentDidMount() {
        const {config} = this.props;

        checkBrowser();
        cssVars();
        logWidgetLoaded({...DEFAULT_CONFIG, ...config});
    }

    componentDidUpdate() {
        const {config} = this.props;
        const {selectedTab, visibleTabs} = this.state;

        if (config.availableTabs && !config.availableTabs.includes(selectedTab)) {
            const firstAvailableAndVisible = config.availableTabs
                .filter(t => visibleTabs.includes(t))
                .shift();

            const selectedTab = firstAvailableAndVisible || config.defaultTab;
            this.onChangeTab(selectedTab);
        }
    }

    onChangeTab = (selectedTab) => {
        if (selectedTab !== this.state.selectedTab) {
            localStorage.setItem(STORAGE_TAB_KEY, selectedTab);

            this.setState({
                selectedTab,
                changedManually: true
            });
        }
    };

    onTabLoaded = ({tab, isEmpty, price, date}) => {
        const {visibleTabs: visible, hiddenTabs: hidden, tabDetails, changedManually} = this.state;
        const {config} = this.props;

        const configuration = {...DEFAULT_CONFIG, ...config};

        const {defaultTab, availableTabs, previousSelection} = configuration;

        const visibleTabs = this.getVisibleTabs(isEmpty, visible, tab);
        const hiddenTabs = this.getHiddenTabs(isEmpty, hidden, tab);

        let updates = {
            visibleTabs,
            hiddenTabs,
            tabDetails: {...tabDetails, [tab]: {price, date}}
        };

        if (!changedManually) {
            if (previousSelection && previousSelection.previousTab && previousSelection.previousTab === tab) {
                updates.selectedTab = previousSelection.previousTab;
                updates.changedManually = true;
            } else {
                const isDefaultTabAvailable = visibleTabs.length === 0 || visibleTabs.includes(defaultTab) && !hiddenTabs.includes(defaultTab);
                updates.selectedTab = isDefaultTabAvailable ? defaultTab : availableTabs.filter(t => visibleTabs.includes(t))[0];
            }
        }

        this.setState(updates);
    };

    getVisibleTabs = (isEmpty, alreadyVisible, tab) => {
        if (isEmpty) {
            return [...alreadyVisible].filter(t => t !== tab);
        }

        return [...alreadyVisible, tab];
    };

    getHiddenTabs = (isEmpty, alreadyHidden, tab) => {
        if (isEmpty) {
            return [...alreadyHidden, tab];
        }

        return [...alreadyHidden].filter(t => t !== tab);
    };

    render() {
        const {selectedTab, visibleTabs, tabDetails} = this.state;
        const {config} = this.props;

        const configuration = {...DEFAULT_CONFIG, ...config};

        const {defaultTab, availableTabs} = configuration;

        const tabs = visibleTabs.length === 0 ?
            [defaultTab] :
            availableTabs.filter(t => visibleTabs.includes(t));

        return (
            <div id="paazl-root" className="paazl-delimiter">
                <section className="paazl method-select">
                    <Header availableTabs={tabs}
                            selectedTab={selectedTab}
                            tabDetails={tabDetails}
                            config={configuration}
                            onChangeTab={this.onChangeTab}/>

                    <BodyContainer config={configuration}
                                   selectedTab={selectedTab}
                                   onTabLoaded={this.onTabLoaded}/>

                    <StyleContainer style={configuration.style}/>
                </section>
            </div>
        );
    }
}
