import {NETWORK, STYLESHEET_URL, TABS} from "../../components/constants/constants";

const $ = window.jQuery;

let currentSequenceValue = 0;

export const generateId = (prefix) => {
    currentSequenceValue++;

    if (!prefix) {
        return currentSequenceValue;
    }

    return `${prefix}-${currentSequenceValue}`;
};

export const checkBrowser = () => {
    if ('objectFit' in document.documentElement.style === false) {
        $('img.polyfill-object-fit-cover').each(function() {
            let $this = $(this);
            let source, parent;

            if ('objectFit' in $this[0].style === false) {
                source = $this.attr('src');
                parent = $this.parent();

                parent.css('background', 'url(' + source + ')');
                parent.css('background-size', 'cover');
                $this.css('display', 'none');
            } else {
                source = $this.attr('src');
                parent = $this.parent();

                parent.css('background', 'url(' + source + ')');
                parent.css('background-size', 'cover');
                parent.css('background-position', 'center center');
                $this.css('display', 'none');
            }
        });

        $('img.polyfill-object-fit-contain').each(function() {
            let $this = $(this);

            if ('objectFit' in $this[0].style === false) {
                let source = $this.attr('src');
                let parent = $this.parent();
                parent.append("<div class='polyfill-image'></div>");
                let child = parent.find('.polyfill-image');

                child.css({
                    width: '100%',
                    height: '100%'
                });

                child.css('background', 'url(' + source + ')');
                child.css('background-size', 'contain');
                child.css('background-repeat', 'no-repeat');
                $this.css('display', 'none');
            }
        });
    }
};

export const isInternetExplorer = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};

export const loadStylesheets = () => {
    let styles = document.createElement('link');
    styles.id = 'paazl-checkout-min-css';
    styles.rel = 'stylesheet';
    styles.type = 'text/css';
    styles.media = 'screen';
    styles.href = STYLESHEET_URL;
    document.getElementsByTagName('head')[0].appendChild(styles);
};

export const findSelectedOptionForPickup = (pickupLocation) => {
    return pickupLocation &&
        pickupLocation.shippingOptions &&
        pickupLocation.shippingOptions.length > 0 &&
        pickupLocation.shippingOptions.find(o => o.identifier === pickupLocation.shippingOptionIdentifier);
};

export const isStoreOption = (pickupLocation) => {
    let shippingOption = findSelectedOptionForPickup(pickupLocation);
    return shippingOption && shippingOption.network === NETWORK.STORE;
};

export const getPickupType = (isStorePickup, isParcelLocker) => {
    let pickupType = "carrier";
    if (isStorePickup) {
        pickupType = "store";
    } else if (isParcelLocker) {
        pickupType = "locker";
    }

    return pickupType;
};

export const getPickupTab = (isStorePickup, isParcelLocker) => {
    let pickupType = TABS.PICKUP;
    if (isStorePickup) {
        pickupType = TABS.STORE;
    } else if (isParcelLocker) {
        pickupType = TABS.PARCEL_LOCKER;
    }

    return pickupType;
};

