import {
    CURRENCY_SYMBOLS,
    DECIMAL_COMMA_LOCALE,
    DECIMAL_POINT_LOCALE,
    DEFAULT_CONFIG,
    SEPARATORS_FORMAT
} from "../constants/constants";
import TextWrapper from "../util/wrapper/TextWrapper";
import { findSelectedOptionForPickup } from "./AppUtils";
import StringWrapper from "../util/wrapper/StringWrapper";

export const formatPrice = (rate = 0, config = DEFAULT_CONFIG, additionalClassName, numeric) => {
    const {currency, isPricingEnabled, isShowAsExtraCost} = config;

    const currencySymbol = getCurrencySymbol(currency);
    const extraCostSign = getExtraCostSign(isShowAsExtraCost);
    const freeCostWrapper = getFreeCostWrapper({config, isShowAsExtraCost, extraCostSign, currencySymbol, additionalClassName, numeric});

    if (!isPricingEnabled) {
        return "";
    }

    return !rate
        ? freeCostWrapper
        : <StringWrapper content={extraCostSign + currencySymbol + " " + formatRate(rate, config)}
                         additionalClassName={additionalClassName}
                         notranslate/>;
};

export const formatRawPrice = (rate = 0, config = DEFAULT_CONFIG) => {
    const {currency, isShowAsExtraCost} = config;

    const currencySymbol = getCurrencySymbol(currency);
    const extraCostSign = getExtraCostSign(isShowAsExtraCost);
    const numericFreeCost = config.separatorsFormat === SEPARATORS_FORMAT.DECIMAL_POINT ? "0.-" : "0,-";

    return !rate
        ? numericFreeCost
        : `${extraCostSign}${currencySymbol} ${formatRate(rate, config)}`;

};

export const formatRate = (rate, config) => {
    const {currency, separatorsFormat, language} = config;

    let fractionDigits = [
        'BIF', 'CLP', 'DJF', 'GNF','ISK', 'JPY', 'KMF', 'KRW','PYG',
        'RWF', 'UGX', 'UYI','VND', 'VUV', 'XAF', 'XOF', 'XPF'
    ].includes(currency) ? 0 : 2;

    if (!separatorsFormat) {
        if (language) {
            return rate.toLocaleString(config.language, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits
            });
        }
        return rate.toLocaleString(DECIMAL_COMMA_LOCALE, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
    }

    if (separatorsFormat === SEPARATORS_FORMAT.DECIMAL_COMMA) {
        return rate.toLocaleString(DECIMAL_COMMA_LOCALE, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
    }

    if (separatorsFormat === SEPARATORS_FORMAT.DECIMAL_POINT) {
        return rate.toLocaleString(DECIMAL_POINT_LOCALE, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
    }
};

export const getPickupLocationShippingRate = (pickupLocation) => {
    let selectedOption = findSelectedOptionForPickup(pickupLocation);
    return selectedOption && selectedOption.rate || 0;
};

export const getCurrencySymbol = (currencyCode) => {
    currencyCode = currencyCode ? currencyCode.toUpperCase() : "EUR";
    let symbol = CURRENCY_SYMBOLS[currencyCode];
    return symbol ? symbol : CURRENCY_SYMBOLS["EUR"];
};

export const getExtraCostSign = (isShowAsExtraCost) => {
    return isShowAsExtraCost ? "+ " : "";
};

export const getFreeCostWrapper = ({config, isShowAsExtraCost, extraCostSign, currencySymbol, additionalClassName, numeric}) => {
    if (numeric) {
        const numericFreeCost = config.separatorsFormat === SEPARATORS_FORMAT.DECIMAL_POINT ? "0.-" : "0,-";

        return <StringWrapper content={numericFreeCost}
                              additionalClassName={additionalClassName}
                              notranslate/>;
    }

    return isShowAsExtraCost ?
        <TextWrapper id={"common.zeroExtra"}
                     fields={{currencySymbol: extraCostSign + currencySymbol}}
                     additionalClassName={additionalClassName}/> :
        <TextWrapper id={"common.free"} additionalClassName={additionalClassName} capitalize />;
};

export const findMinPrice = (options = []) => {
    const initialRate = options[0] && options[0].rate || 0;
    return options.reduce((min, o) => o.rate < min ? o.rate : min, initialRate);
};


