import {Component} from 'preact';
import ClassNames from "classnames";
import TextWrapper from "../../../util/wrapper/TextWrapper";
import StringWrapper from "../../../util/wrapper/StringWrapper";
import OpeningHoursTooltipLink from "../../opening-hours/OpeningHoursTooltipLink";
import ShippingOptionsDropdown from "./ShippingOptionsDropdown";
import {resolveDateEstimationComponent, TYPE} from "../../../util/DatesEstimationUtils";
import {formatPrice, getPickupLocationShippingRate} from "../../../util/CurrencyUtils";
import LabelAddressForm from "../../form/LabelAddressForm";
import LabelPriceDistanceAsideForm from "../../form/LabelPriceDistanceAsideForm";
import { findSelectedOptionForPickup, getPickupType, isStoreOption } from "../../../util/AppUtils";
import StoreLogoForm from "../../form/StoreLogoForm";
import CarrierLogoForm from "../../form/CarrierLogoForm";
import {formatDistance} from "../../../util/ConversionUtils";
import {getCurrentTranslation} from "../../../../i18n/I18nResolver";

export default class PickupModalListElement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
    }

    onSelectPickupLocation = (e) => {
        const {pickupLocation, onSelectPickupLocation} = this.props;

        if (!pickupLocation.checked) {
            onSelectPickupLocation && onSelectPickupLocation(pickupLocation.code);
        }

        if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
            this.inputRef && this.inputRef.focus();
        }
    };

    createRefWrap = (ref) => {
        const {pickupLocation, createPickupLocationRef} = this.props;
        createPickupLocationRef && createPickupLocationRef(ref, pickupLocation.code);
        return ref;
    };

    setInputRef = (node) => {
        this.inputRef = node;
    };

    setDropdownRef = (node) => {
        this.dropdownRef = node;
    };

    onPickupHere = () => {
        const {pickupLocation, onPickupHere} = this.props;
        onPickupHere && onPickupHere(pickupLocation);
    };

    getAvailabilityEstimate = () => {
        const {pickupLocation, config} = this.props;

        let shippingOption = findSelectedOptionForPickup(pickupLocation);

        if (shippingOption) {
            return resolveDateEstimationComponent(TYPE.PICKUP_LONG, shippingOption, config.deliveryRangeFormat);
        }
    };

    onFocusIn = () => {
        this.setState({
            focused: true
        });
    };

    onFocusOut = () => {
        this.setState({
            focused: false
        });
    };

    render() {
        const {pickupLocation, onSelectShippingOption, config, isStorePickup, isParcelLocker, storeLogo} = this.props;
        const {focused} = this.state;

        const {checked} = pickupLocation;

        const isSentenceFormatReversed = ['chi', 'jpn'].includes(config.language.toLowerCase());
        const availabilityEstimate = this.getAvailabilityEstimate();

        const isStore = isStorePickup || isStoreOption(pickupLocation);

        const distance = formatDistance(pickupLocation.distance);
        const isUnknownDistance = distance === getCurrentTranslation().pickupLocations.distance;

        const rate = getPickupLocationShippingRate(pickupLocation);
        const price = formatPrice(rate, config, "point__cost");

        const idPrefix = isStore ? "store-" : "point-";
        const pickupType = getPickupType(isStore, isParcelLocker);

        return (
            <li className="window__listing__item" ref={this.createRefWrap}>
                <article className="point point--compact paazl-delimiter"
                         key={pickupLocation.code}
                         onClick={this.onSelectPickupLocation}>

                    <div className={ClassNames("point__label", {"point--store": isStore && storeLogo, checked, focused})}>
                        <div className="paazl-delimiter">
                            <div className={ClassNames({"point--store": isStore && storeLogo})}>

                                <header className="point__header">
                                    <section className='point__main'>
                                        <StoreLogoForm isStorePickup={isStore}
                                                       storeLogo={storeLogo}
                                                       config={config}/>

                                        <section className="point__main__body">
                                            <h4 className="point__title">
                                                <input type="radio"
                                                       className="point__hidden"
                                                       id={idPrefix + "modal-" + pickupLocation.code}
                                                       name={isStore ? "selected-store" : "selected-point"}
                                                       ref={this.setInputRef}
                                                       checked={checked}
                                                       onFocus={this.onFocusIn}
                                                       onBlur={this.onFocusOut}/>

                                                <label htmlFor={idPrefix + "modal-" + pickupLocation.code}>
                                                    <StringWrapper content={pickupLocation.name}
                                                                   notranslate/>
                                                </label>
                                            </h4>
                                        </section>
                                        {
                                            !isStorePickup && <LabelAddressForm address={pickupLocation.address}
                                                                                storeLogoMissing={!storeLogo}
                                                                                withCarrierLogo={true}/>
                                        }

                                        {
                                            isUnknownDistance && distance
                                        }

                                    </section>

                                    <section>
                                        {
                                            !isStore && <CarrierLogoForm config={config}
                                                                               shippingOption={pickupLocation.shippingOptions[0]}/>
                                        }

                                        {
                                            !isUnknownDistance
                                                ? <LabelPriceDistanceAsideForm pickupLocation={pickupLocation}
                                                                           config={config}/>
                                                : price
                                        }

                                    </section>

                                </header>

                                {
                                    isStorePickup && <LabelAddressForm address={pickupLocation.address}
                                                                       storeLogoMissing={!storeLogo}/>
                                }

                                <ul className="point__options">
                                    <OpeningHoursTooltipLink openingTimes={pickupLocation.openingTimes}/>
                                </ul>
                            </div>

                            <nav className="point__select">
                                <ShippingOptionsDropdown pickupLocation={pickupLocation}
                                                         currentRate={getPickupLocationShippingRate(pickupLocation)}
                                                         onSelectShippingOption={onSelectShippingOption}
                                                         setDropdownRef={this.setDropdownRef}/>

                                <button className="point__button button button--primary" onClick={this.onPickupHere}>
                                    <TextWrapper id="pickupLocations.pickUpHere"/>
                                </button>

                                <aside className="point__message point__message--logo">
                                    <span className={ClassNames({"point__message__text": availabilityEstimate})}>
                                        {
                                            availabilityEstimate &&
                                            <TextWrapper id={"pickupLocations." + pickupType + ".packageWillBeReady"}
                                                         contentBefore={isSentenceFormatReversed ? availabilityEstimate : ''}
                                                         contentAfter={isSentenceFormatReversed ? '' : availabilityEstimate}
                                                         spaceBetweenPrefix={isSentenceFormatReversed}
                                                         spaceBetweenSuffix={!isSentenceFormatReversed}/>
                                        }
                                    </span>
                                </aside>
                            </nav>
                        </div>
                    </div>
                </article>
            </li>
        );
    }
}