import { Component } from 'preact';
import StringWrapper from "../../util/wrapper/StringWrapper";

export default class OpeningHoursRow extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {dayOfWeek, timeFirstPart, timeSecondPart} = this.props;

        return (
            <li className="tooltip__listing__item">
                <span className="paazl-delimiter">
                    <StringWrapper content={dayOfWeek}
                                   additionalClassName="tooltip__listing__item--day"/>

                    <span className="tooltip__listing__item--time">
                        {
                            timeFirstPart &&
                            <StringWrapper additionalClassName="widget-tooltip--time-part"
                                           content={timeFirstPart}
                                           notranslate
                            />
                        }
                        {
                            timeSecondPart &&
                            <StringWrapper additionalClassName="widget-tooltip--time-part"
                                           content={timeSecondPart}
                                           notranslate
                            />
                        }
                    </span>
                </span>
            </li>
        );
    }

}