import axios from 'axios';
import {BASE_URL, LOGGING_URL} from '../constants/constants';

let instance;

export const init = () => {
    if (instance === undefined) {
        instance = axios.create({
            baseURL: BASE_URL,
            timeout: 100000
        });
    }
    return instance;
};

export const doGet = (url, config) => {
    init();

    const {apiKey} = config;

    instance.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;

    return instance.get(url);
};

export const doPost = (url, config) => {
    init();

    const {apiKey, ...postBody} = config;

    instance.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;

    return instance.post(url, postBody);
};

export const sendLogReport = ({logLevel, logEvent, message, requestBody, config}) => {
    init();

    const {apiKey, apiSecret, ...loggableConfig} = config;

    let loggableRequestBody;
    if (requestBody) {
        const {apiKey, apiSecret, ...allowedToLogBody} = requestBody;
        loggableRequestBody = allowedToLogBody;
    }


    instance.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;

    return instance.post(`${LOGGING_URL}/${logLevel.toLowerCase()}`, {
        logEvent,
        message,
        requestBody: loggableRequestBody,
        config: loggableConfig
    });
};
