import { Component } from 'preact';

export default class Blue extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                    /* Global */
                    --brand-global-radius: 0;

                    /* Colors */
                    --brand-color-background: #FFF;
                    --brand-color-secondary: #FFF;
                    --brand-color-border: #D9D9D9;
                    --brand-color-body: #183051;
                    --brand-color-primary: #3376CA;
                    --brand-color-focus: #FFCC00;

                    /* Fonts */
                    --brand-font-family-title: "Roboto Condensed", serif;
                    --brand-font-family-body: "Roboto Condensed";
                    --brand-font-weight-regular: 400;
                    --brand-font-weight-bold: 700;

                    /* Title */
                    --brand-title-weight: 400;
                    --brand-title-transform: uppercase;
                    --brand-title-spacing: 0;

                    /* Input */
                    --brand-input-border-width: 1px;
                    --brand-input-border-color: #183051;

                    /* Button */
                    --brand-button-radius: 0;
                    --brand-button-border-size: 0;
                    --brand-button-border-color: transparent;
                    --brand-button-font-color: #FFF;
                    --brand-button-font-size: 14px;
                    --brand-button-font-weight: 400;
                    --brand-button-font-transform: uppercase;
                    --brand-button-font-spacing: 1px;
                  }
            `
            }}></style>

        );
    }
}