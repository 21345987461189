import { Component } from 'preact';
import { IMAGES_BASE_URL } from "../../constants/constants";

export default class PostNumberInput extends Component {

    constructor(props) {
        super(props);
    }

    getInputText = () => {
        const {postNumber, selectedOption} = this.props;
        let delimiter = selectedOption.carrier.name === "DHL_DE" ? " " : "";

        return postNumber && postNumber.match(/.{1,3}/g).join(delimiter);
    };

    onChangeInputText = (e) => {
        const {onChange, selectedOption} = this.props;

        if (selectedOption.carrier.name === "DHL_DE") {
           return onChange && onChange(e.target.value.replace(/\D/g,''));
        }

        return onChange && onChange(e.target.value);
    };

    checkEnterKeyPress = (e) => {
        const {onChangeConfirm} = this.props;

        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            onChangeConfirm();
        }
    };


    render() {
        const {onChangeConfirm, selectedOption} = this.props;

        return (
            <div className="postnumber__input">
                <label className="postnumber__label">
                    <figure className="postnumber__logo">
                        <img className="postnumber__logo__source" src={IMAGES_BASE_URL + selectedOption.carrier.name + ".png"}/>
                    </figure>
                </label>

                <input className="postnumber__field"
                       type="text"
                       value={this.getInputText()}
                       onInput={this.onChangeInputText}
                       onBlur={onChangeConfirm}
                       onKeyPress={this.checkEnterKeyPress}
                       placeholder="123 456 789 0"
                       id="postnumber-input"
                       maxLength="13"/>
            </div>
        );
    }
}