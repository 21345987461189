import { Component } from 'preact';
import GoogleMapReact from 'google-map-react';
import MapMarker from "./MapMarker";
import {
    GOOGLE_API_KEY,
    GOOGLE_MAPS_API_KEY_URL,
    MAP_ZOOMS,
    METER_IN_DEGREE,
    TOOLTIP_TOP_MARGIN_IN_PIXEL
} from "../../../constants/constants";
import { doGet } from "../../../util/NetworkUtils";

export default class PickupLocationMapForm extends Component {

    static defaultProps = {
        open: false
    };

    constructor(props) {
        super(props);
        this.state = {
            googleMapsApiKey: null,
            mapLoaded: false,
            zoom: 15,
            size: {
                width: 700,
                height: 500
            },
            center: {
                lat: 50.449549,
                lng: 30.525376
            }
        };
    }

    componentDidMount() {
        const {config} = this.props;

        if (!window.PAAZL_CHECKOUT_WIDGET_API_URL) {
            doGet(GOOGLE_MAPS_API_KEY_URL, config)
                .then(response => {
                    this.setState({googleMapsApiKey: response.data});
                })
                .catch(error => {
                    console.error(error);
                    this.setState({googleMapsApiKey: GOOGLE_API_KEY});
                });
        } else {
            this.setState({googleMapsApiKey: GOOGLE_API_KEY});
        }

        window.addEventListener("resize", this.windowResizeEventListener);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResizeEventListener);
    }

    componentDidUpdate(previousProps, previousState) {
        const {pickupLocations} = this.props;

        if (previousState.zoom !== this.state.zoom) {
            this.updateMapPosition(pickupLocations);
        }
    }

    onMapLoaded = () => {
        this.setState({mapLoaded: true});
    };

    setTooltipRef = (node) => {
        this.tooltipRef = node;
    };

    windowResizeEventListener = () => this.updateMapPosition();

    updateMapPosition = (pickupLocations) => {
        const {open, pickupLocations: propsPickupLocations} = this.props;
        const {center: oldCenter, zoom} = this.state;

        if (!pickupLocations) {
            pickupLocations = propsPickupLocations;
        }

        if (open) {
            let currentPoint = pickupLocations && pickupLocations.find(pickupLocation => pickupLocation.checked);
            let center = currentPoint ? this.calculateMapCenter(currentPoint, zoom) : oldCenter;

            this.setState({
                center
            });
        }
    };

    equalsLists = (primary, secondary) => {
        if (!primary || !secondary || primary.length !== secondary.length) {
            return false;
        }

        for (let i = 0; i < primary.length; i++) {
            if (primary[i].code !== secondary[i].code) {
                return false;
            }
        }

        return true;
    };

    calculateMapCenter = (pickupLocation, zoom) => {
        if (pickupLocation && pickupLocation.coordinates) {
            return {
                lat: pickupLocation.coordinates.latitude + this.calculateMarginTopDistance(zoom),
                lng: pickupLocation.coordinates.longitude
            };
        }
    };

    calculateMarginTopDistance = (zoom) => {
        if (zoom === -1 || !this.tooltipRef) {
            return 0;
        }

        const zoomMeters = MAP_ZOOMS[zoom];
        return METER_IN_DEGREE * zoomMeters * ((TOOLTIP_TOP_MARGIN_IN_PIXEL + this.tooltipRef.clientHeight) - (this.state.size.height / 2));
    };

    onMapChange = (mapProps) => {
        const {zoom, size, center} = mapProps;
        const {zoom: oldZoom, size: oldSize, center: oldCenter} = this.state;

        if (zoom !== 0 && zoom !== oldZoom ||
            oldSize.width !== size.width || oldSize.height !== size.height ||
            oldCenter.lat !== center.lat || oldCenter.lng !== center.lng) {

            this.setState({
                zoom,
                size,
                center
            });
        }
    };

    render() {
        const {pickupLocations, onSelectPickupLocation, open, simple, isStorePickup, storeLogo, config} = this.props;
        const {center, zoom, googleMapsApiKey, mapLoaded} = this.state;

        const containerClassName = simple ? "window__map-simple" : "window__map";

        return (
            <div className={containerClassName}>
                {
                    open && googleMapsApiKey &&
                    <div className="map-container">
                        <GoogleMapReact bootstrapURLKeys={{key: [googleMapsApiKey]}}
                                        center={center}
                                        onTilesLoaded={this.onMapLoaded}
                                        zoom={zoom}
                                        onChange={this.onMapChange}>
                            {
                                mapLoaded && pickupLocations && pickupLocations.length > 0 &&
                                pickupLocations
                                    .filter(pickupLocation => this.hasCoordinates(pickupLocation))
                                    .map(pickupLocation => {
                                        return <MapMarker lat={pickupLocation.coordinates.latitude}
                                                          lng={pickupLocation.coordinates.longitude}
                                                          pickupLocation={pickupLocation}
                                                          isStorePickup={isStorePickup}
                                                          storeLogo={storeLogo}
                                                          onSelectPickupLocation={onSelectPickupLocation}
                                                          updateMapPosition={this.updateMapPosition}
                                                          setTooltipRef={this.setTooltipRef}
                                                          config={config}/>;
                                    })
                            }

                        </GoogleMapReact>
                    </div>
                }
            </div>
        );
    }

    hasCoordinates(pickupLocation) {
        return pickupLocation.coordinates && pickupLocation.coordinates.latitude && pickupLocation.coordinates.longitude;
    }
}