import {Component} from 'preact';
import ClassNames from "classnames";
import TitleForm from "./form/TitleForm";
import FooterForm from "./form/FooterForm";
import PickupListContainer from "./list/PickupListContainer";
import PickupModal from "./modal/PickupModal";
import {checkout, loadPickupLocations} from "../service/restApiService";
import SimpleMapModal from "./modal/SimpleMapModal";
import {isPickupConfigChanged} from "../util/ConfigUtils";
import Loader from "../common/Loader";
import {doGet} from "../util/NetworkUtils";
import {STORE_LOGO_URL, TABS} from "../constants/constants";
import {findMinPrice} from "../util/CurrencyUtils";
import {findMinDate} from "../util/DatesEstimationUtils";
import {findSelectedOptionForPickup} from "../util/AppUtils";

export default class PickupContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pickupLocations: [],
            storeLogo: "",
            modalOpened: false,
            modalSimpleMapOpened: false,
            loading: true,
            previousSelectionApplied: false,
            scrollTop: 0,
            accountNumberWithState: {},
            initializedOn: props.config.initializedOn
        };
    }

    componentDidMount = () => {
        const {config, isStorePickup} = this.props;

        this.fetchData(config, isStorePickup);
    };

    componentDidUpdate (previousProps) {
        const {config, isStorePickup, isParcelLocker} = this.props;

        if (isPickupConfigChanged(config, previousProps.config) || isStorePickup !== previousProps.isStorePickup || isParcelLocker !== previousProps.isParcelLocker) {
            this.fetchData(config, isStorePickup);
        } else if (config.initializedOn !== previousProps.config.initializedOn) {
            this.setState({initializedOn: config.initializedOn});
        }

        if (!previousProps.tabActive && this.props.tabActive) {
            this.checkoutPickupLocation(true);
        }
    }

    fetchData = (config, isStorePickup) => {
        this.loadPickupLocations(config);

        if (isStorePickup || !config.availableTabs.includes(TABS.STORE)) {
            this.loadStoreLogo(config);
        }
    };

    toggleMapModal = (modalOpened) => {
        this.switchOpenModalClass(modalOpened);

        this.setState({
            modalOpened
        });
    };

    toggleSimpleMapModal = (modalSimpleMapOpened, pickupLocation) => {
        this.switchOpenModalClass(modalSimpleMapOpened);

        this.setState({
            modalSimpleMapOpened,
            modalSimpleMapPickupLocation: pickupLocation
        });
    };

    switchOpenModalClass = (opened) => {
        const {scrollTop} = this.state;

        const htmlBodyElement = document.querySelector('body');

        const htmlClassList = document.querySelector('html').classList;
        const bodyClassList = htmlBodyElement.classList;

        if (opened) {
            const scrolledTop = htmlBodyElement.scrollTop || document.scrollingElement && document.scrollingElement.scrollTop;

            htmlClassList.add('paazl-modal-open');
            bodyClassList.add('paazl-modal-open');

            this.setState({
                scrollTop: scrolledTop
            });
        } else {
            htmlClassList.remove('paazl-modal-open');
            bodyClassList.remove('paazl-modal-open');

            htmlBodyElement.scrollTo && htmlBodyElement.scrollTo(0, scrollTop);
            document.scrollingElement && document.scrollingElement.scrollTo(0, scrollTop);
        }
    };

    loadPickupLocations = (config) => {
        const {isStorePickup, isParcelLocker} = this.props;
        const {loadCarrierBasedData} = this.props.config;
        loadPickupLocations({...config, includeExternalDeliveryDates: loadCarrierBasedData}, isStorePickup, isParcelLocker, this.setPickupLocations);
    };

    loadStoreLogo = (config) => {
        doGet(STORE_LOGO_URL, config)
            .then(res => {
                this.setState({
                    storeLogo: !res || !res.data ? "" : `data:image/svg+xml;base64,${res.data}`
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    storeLogo: ""
                });
            });
    };

    setPickupLocations = (pickupLocations, loading = false) => {
        const {previousSelectionApplied: oldPreviousSelectionApplied} = this.state;
        const {tabActive, isStorePickup, isParcelLocker, onTabLoaded, config} = this.props;

        const isEmpty = pickupLocations.length === 0;

        let previousSelectionApplied = oldPreviousSelectionApplied;


        if (!isEmpty && !previousSelectionApplied && this.isTabPreviouslySelected()) {
            previousSelectionApplied = true;
            pickupLocations = [...pickupLocations].map(p => {
                const checked = p.code === config.previousSelection.previousPickupCode;
                const shippingOptionIdentifier = checked ? config.previousSelection.previousOption : p.shippingOptions[0].identifier;

                return {...p, checked, shippingOptionIdentifier};
            });
        } else if (!isEmpty) {
            pickupLocations[0].checked = true;
            pickupLocations.forEach((element) => {
                element.shippingOptionIdentifier = element.shippingOptions[0].identifier;
            });
        }

        this.setState(
            {pickupLocations, loading, previousSelectionApplied},
            () => this.checkoutPickupLocation(tabActive)
        );

        const tab = this.resolveTab(isStorePickup, isParcelLocker);

        const options = pickupLocations.reduce((acc, p) => acc.concat(p.shippingOptions), []);

        const price = !isEmpty && findMinPrice(options);
        const date = !isEmpty && findMinDate(options, config.deliveryRangeFormat);

        onTabLoaded({
            tab,
            isEmpty,
            price,
            date
        });
    };

    resolveTab = (isStorePickup, isParcelLocker) => {
        let tab = TABS.PICKUP;

        if (isStorePickup) {
            tab = TABS.STORE;
        }

        if (isParcelLocker) {
            tab = TABS.PARCEL_LOCKER;
        }

        return tab;
    };

    isTabPreviouslySelected = () => {
        const {isStorePickup, config} = this.props;
        const {previousSelection} = config;

        return previousSelection &&
            previousSelection.previousPickupCode &&
            (isStorePickup && previousSelection.previousTab === TABS.STORE ||
                !isStorePickup && previousSelection.previousTab === TABS.PICKUP ||
                !isStorePickup && previousSelection.previousTab === TABS.PARCEL_LOCKER);
    };

    onPickupHere = (pickupLocations) => {
        this.setState(
            {pickupLocations},
            () => {
                this.checkoutPickupLocation(true);
                this.toggleMapModal(false);
            }
        );
    };

    checkoutPickupLocation = (tabActive) => {
        const {pickupLocations, accountNumberWithState} = this.state;
        const pickupLocation = pickupLocations.find(element => element.checked);
        const selectedOption = findSelectedOptionForPickup(pickupLocation);
        const needsCustomerNumber = selectedOption && selectedOption.needsCustomerNumber;
        const accountNumberValid = accountNumberWithState && accountNumberWithState.accountNumber && accountNumberWithState.valid;

        if (pickupLocation && tabActive && !needsCustomerNumber) {
            checkout(this.props.config, pickupLocation.shippingOptionIdentifier, pickupLocation.code);
        }
        if (pickupLocation && tabActive && needsCustomerNumber && accountNumberValid) {
            checkout(this.props.config, pickupLocation.shippingOptionIdentifier, pickupLocation.code, selectedOption.exactDay, accountNumberWithState.accountNumber);
        }
    };

    onChangeAccountNumber = (accountNumber, valid) => {
        this.setState({
            accountNumberWithState: {accountNumber, valid}
        });
    };

    onSelectPickupLocation = (code) => {
        const {pickupLocations} = this.state;

        this.setState({
                pickupLocations: pickupLocations.slice().map(o => {
                    return {...o, checked: o.code === code};
                })
            },
            () => this.checkoutPickupLocation(true)
        );
    };

    getSelectedPickupLocationId = () => {
        const {pickupLocations} = this.state;

        if (pickupLocations) {
            let pickupLocationIndex = pickupLocations.findIndex((o) => o.checked);
            return pickupLocationIndex !== -1 ? pickupLocations[pickupLocationIndex].code : -1;
        }

        return -1;
    };

    render() {
        const {tabActive, isStorePickup, isParcelLocker, config} = this.props;
        const {modalOpened, pickupLocations, loading, modalSimpleMapOpened, modalSimpleMapPickupLocation, storeLogo, accountNumberWithState, initializedOn} = this.state;

        const panelClassName = ClassNames(
            "body__panel panel method-select__panel",
            "initialized-" + initializedOn,
            {"panel--selected": tabActive}
        );

        const hasPickupLocations = pickupLocations && pickupLocations.length > 0;

        return (
            <div id={isStorePickup ? "tab-panel-store" : "tab-panel-pickup"}
                 className={panelClassName}
                 aria-labelledby={isStorePickup ? "tab-button-store" : "tab-button-pickup"}
                 aria-hidden={!tabActive}>

                <div className="method-select__tab-content">
                    <Loader isLoading={loading}/>

                    {
                        !loading &&
                        <TitleForm isStorePickup={isStorePickup}
                                   isParcelLocker={isParcelLocker}/>
                    }

                    {
                        !loading &&
                        <PickupListContainer toggleModal={this.toggleMapModal}
                                             toggleSimpleMapModal={this.toggleSimpleMapModal}
                                             pickupLocations={pickupLocations.slice(0, config.initialPickupLocations)}
                                             onSelectPickupLocation={this.onSelectPickupLocation}
                                             accountNumberWithState={accountNumberWithState}
                                             isStorePickup={isStorePickup}
                                             isParcelLocker={isParcelLocker}
                                             storeLogo={storeLogo}
                                             config={config}/>
                    }

                    {
                        !loading && hasPickupLocations &&
                        <FooterForm pickupLocations={pickupLocations}
                                    tabActive={tabActive}
                                    isStorePickup={isStorePickup}
                                    isParcelLocker={isParcelLocker}
                                    config={config}
                                    onChangeAccountNumber={this.onChangeAccountNumber}/>
                    }

                    {
                        !loading &&
                        <PickupModal open={modalOpened}
                                     toggleModal={this.toggleMapModal}
                                     isStorePickup={isStorePickup}
                                     isParcelLocker={isParcelLocker}
                                     storeLogo={storeLogo}
                                     config={config}
                                     initialPickupLocations={pickupLocations}
                                     onPickupHere={this.onPickupHere}
                                     selectedPickupLocationId={this.getSelectedPickupLocationId()}/>
                    }

                    {
                        !loading &&
                        <SimpleMapModal open={modalSimpleMapOpened}
                                        toggleMapModal={this.toggleSimpleMapModal}
                                        pickupLocation={modalSimpleMapPickupLocation}
                                        isStorePickup={isStorePickup}
                                        storeLogo={storeLogo}
                                        config = {config}/>
                    }
                </div>
            </div>
        );
    }
}