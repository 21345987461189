import { Component } from 'preact';
import { resolveDateEstimationComponent, TYPE } from "../../util/DatesEstimationUtils";
import TextWrapper from "../../util/wrapper/TextWrapper";

export default class FooterForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {selectedOption, config} = this.props;

        const isSentenceFormatReversed = ['chi', 'jpn'].includes(config.language.toLowerCase());

        const footerText = resolveDateEstimationComponent(TYPE.DELIVERY_LONG, selectedOption, config.deliveryRangeFormat);

        const clockIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" aria-hidden="true" focusable="false">
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0h20v20H0z"/>
                <path className="colorable" fill="#000" fill-rule="nonzero"
                      d="M9.99166667 1.66666667c-4.6 0-8.325 3.73333333-8.325 8.33333333 0 4.6 3.725 8.3333333 8.325 8.3333333C14.6 18.3333333 18.3333333 14.6 18.3333333 10S14.6 1.66666667 9.99166667 1.66666667zM10 16.6666667c-3.68333333 0-6.66666667-2.9833334-6.66666667-6.6666667 0-3.68333333 2.98333334-6.66666667 6.66666667-6.66666667 3.6833333 0 6.6666667 2.98333334 6.6666667 6.66666667 0 3.6833333-2.9833334 6.6666667-6.6666667 6.6666667zm.4166667-10.83333337H9.16666667v4.99999997l4.37500003 2.625.625-1.025-3.75-2.225V5.83333333z"/>
            </g>
        </svg>;

        return (
            <footer className="body__footer footer">
                {
                    footerText &&

                    <aside className="footer__message" role="alert" aria-live="assertive">
                        <TextWrapper Tag="p"
                                     id="shippingOptions.packageWillBeDelivered"
                                     contentBefore={
                                         isSentenceFormatReversed ?
                                             <span>
                                                 {clockIcon}
                                                 {footerText}
                                             </span> :
                                             clockIcon
                                     }
                                     contentAfter={isSentenceFormatReversed ? '' : footerText}
                                     spaceBetweenPrefix={isSentenceFormatReversed}
                                     spaceBetweenSuffix={!isSentenceFormatReversed}/>
                    </aside>
                }
            </footer>
        );
    }
}
