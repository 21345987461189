import { Component } from 'preact';
import ClassNames from "classnames";
import StringWrapper from "../../util/wrapper/StringWrapper";

export default class LabelAddressForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {address, storeLogoMissing, withCarrierLogo} = this.props;

        const addressLine = [address.street, address.streetNumber, address.streetNumberSuffix].join(' ');
        const cityLine = [address.postalCode, address.city].join(' ');

        return (
            <div className="paazl-delimiter">
                <p className={ClassNames(withCarrierLogo ? "" : "point__label__address", {"store-logo-missing": storeLogoMissing})}>

                    <StringWrapper content={addressLine}
                                   additionalClassName="point__address"
                                   notranslate/>

                    <StringWrapper content={cityLine}
                                   additionalClassName="point__postal"
                                   notranslate/>
                </p>
            </div>
        );
    }
}