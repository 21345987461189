import { Component } from 'preact';
import { MarkupText, Text } from "preact-i18n";
import ClassNames from "classnames";

export default class TextWrapper extends Component {

    static defaultProps = {
        Tag: 'span',
        notranslate: false,
        additionalClassName: "",
        spaceBefore: false,
        spaceAfter: false
    };

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate = (nextProps) => {
        return this.props.id !== nextProps.id ||
            JSON.stringify(this.props.fields) !== JSON.stringify(nextProps.fields) ||
            this.props.contentBefore !== nextProps.contentBefore ||
            this.props.contentAfter !== nextProps.contentAfter;
    };

    render() {
        const {
            Tag,
            htmlId,
            id,
            fields,
            notranslate,
            additionalClassName,
            spaceBefore,
            spaceAfter,
            contentBefore,
            contentAfter,
            spaceBetweenPrefix,
            spaceBetweenSuffix,
            capitalize
        } = this.props;

        return (
            <Tag id={htmlId} className={ClassNames(additionalClassName, {notranslate, capitalize})}>
                {spaceBefore && '\u00A0'}

                {contentBefore}

                {spaceBetweenPrefix && '\u00A0'}


                {
                    !fields ?
                        <Text id={id}/> :
                        <MarkupText id={id} fields={fields}/>
                }

                {spaceBetweenSuffix && '\u00A0'}

                {contentAfter}

                {spaceAfter && '\u00A0'}
            </Tag>
        );
    }
}