import { Component } from 'preact';
import { format } from "../../util/DateTimeUtils";
import { FORMAT_MASKS } from "../../constants/constants";
import StringWrapper from "../../util/wrapper/StringWrapper";

export default class SelectDayDropdown extends Component {

    constructor(props) {
        super(props);
    }

    onSelectGroup = (event) => {
        this.props.onSelectGroup(+event.target.value);
    };

    render() {
        const {optionGroups, activeGroup} = this.props;

        return (
            <div className="day-select__select select select--calendar">
                <select className="select__source"
                        value={activeGroup}
                        onChange={this.onSelectGroup}
                        data-no-fancy-select="true">
                    {
                        optionGroups && optionGroups.map((group, index) => {
                            return (
                                <option value={index}>
                                    <StringWrapper content={format(group.date, FORMAT_MASKS.DELIVERY_ESTIMATE_DATE_FORMAT)} />
                                </option>
                            );
                        })
                    }
                </select>
            </div>
        );
    }
}