import {DEFAULT_CONFIG, NETWORK, TABS} from '../constants/constants';
import {resolveLocale} from "../../i18n/I18nResolver";

export const getDefaultTab = (config) => {
    if (config.availableTabs && config.availableTabs.length) {
        return config.availableTabs[0];
    }

    return DEFAULT_CONFIG.defaultTab;
};

export const isDeliveryConfigChanged = (config, newConfig) => {
    return config.apiKey !== newConfig.apiKey ||
        config.version !== newConfig.version ||
        config.token !== newConfig.token ||
        config.consigneeCountryCode !== newConfig.consigneeCountryCode ||
        config.consignorCountryCode !== newConfig.consignorCountryCode ||
        config.consigneePostalCode !== newConfig.consigneePostalCode ||
        config.consignorPostalCode !== newConfig.consignorPostalCode ||
        config.numberOfProcessingDays !== newConfig.numberOfProcessingDays ||
        config.tags !== newConfig.tags ||
        JSON.stringify(config.deliveryDateOptions) !== JSON.stringify(newConfig.deliveryDateOptions) ||
        config.shippingOptionsLimit !== newConfig.shippingOptionsLimit ||
        config.currency !== newConfig.currency ||
        config.language !== newConfig.language || config.currency !== newConfig.currency ||
        JSON.stringify(config.sortingModel) !== JSON.stringify(newConfig.sortingModel) ||
        JSON.stringify(config.shipmentParameters) !== JSON.stringify(newConfig.shipmentParameters);
};

export const getDeliveryConfig = (config) => {
    const {
        apiKey,
        token,
        consigneeCountryCode,
        consignorCountryCode,
        consigneePostalCode,
        consignorPostalCode,
        numberOfProcessingDays,
        tags,
        deliveryDateOptions,
        shipmentParameters,
        language,
        currency,
        shippingOptionsLimit,
        sortingModel
    } = normalizeConfig({...config});


    return {
        apiKey,
        token,
        consigneeCountryCode,
        consignorCountryCode,
        consigneePostalCode,
        consignorPostalCode,
        numberOfProcessingDays,
        tags,
        deliveryDateOptions,
        shipmentParameters,
        locale: resolveLocale(language),
        limit: shippingOptionsLimit,
        start: 0,
        currency: currency,
        sortingModel: Array.isArray(sortingModel) ? sortingModel.find(e => e.tab === TABS.DELIVERY) : sortingModel
    };
};

export const isPickupConfigChanged = (config, newConfig) => {
    return config.apiKey !== newConfig.apiKey ||
        config.version !== newConfig.version ||
        config.token !== newConfig.token ||
        config.consigneeCountryCode !== newConfig.consigneeCountryCode ||
        config.consignorCountryCode !== newConfig.consignorCountryCode ||
        config.consigneePostalCode !== newConfig.consigneePostalCode ||
        config.consignorPostalCode !== newConfig.consignorPostalCode ||
        config.numberOfProcessingDays !== newConfig.numberOfProcessingDays ||
        config.crossBorderStores !== newConfig.crossBorderStores ||
        config.tags !== newConfig.tags ||
        JSON.stringify(config.deliveryDateOptions) !== JSON.stringify(newConfig.deliveryDateOptions) ||
        config.pickupLocationsLimit !== newConfig.pickupLocationsLimit ||
        config.pickupLocationsPageLimit !== newConfig.pickupLocationsPageLimit ||
        config.initialPickupLocations !== newConfig.initialPickupLocations ||
        config.language !== newConfig.language || config.currency !== newConfig.currency ||
        JSON.stringify(config.sortingModel) !== JSON.stringify(newConfig.sortingModel) ||
        JSON.stringify(config.shipmentParameters) !== JSON.stringify(newConfig.shipmentParameters);
};

export const preparePickupLocationsRequest = (config, isStorePickup, isParcelLocker) => {
    const {
        apiKey,
        token,
        consigneeCountryCode,
        consignorCountryCode,
        consigneePostalCode,
        consignorPostalCode,
        numberOfProcessingDays,
        crossBorderStores,
        tags,
        deliveryDateOptions,
        shipmentParameters,
        language,
        pickupLocationsLimit,
        includeExternalDeliveryDates,
        sortingModel
    } = normalizeConfig({...config});

    const network = getNetwork(config, isStorePickup);
    const excludeLockers = !isParcelLocker && config.availableTabs.includes(TABS.PARCEL_LOCKER);
    const tab = isStorePickup ? TABS.STORE : isParcelLocker ? TABS.PARCEL_LOCKER : TABS.PICKUP;

    return {
        apiKey,
        token,
        network,
        lockersOnly: isParcelLocker,
        excludeLockers,
        consigneeCountryCode,
        consignorCountryCode,
        consigneePostalCode,
        consignorPostalCode,
        numberOfProcessingDays,
        crossBorderStores,
        tags,
        deliveryDateOptions,
        shipmentParameters,
        locale: resolveLocale(language),
        limit: pickupLocationsLimit,
        includeExternalDeliveryDates,
        sortingModel: Array.isArray(sortingModel) ? sortingModel.find(e => e.tab === tab) : null
    };
};

export const normalizeConfig = (config) => {
    const normalizedConfig = {...DEFAULT_CONFIG, ...config};

    if (isNaN(normalizedConfig.shippingOptionsLimit) || normalizedConfig.shippingOptionsLimit < 1 || normalizedConfig.shippingOptionsLimit > 99) {
        logInvalidParameterError('shippingOptionsLimit');
        normalizedConfig.shippingOptionsLimit = DEFAULT_CONFIG.shippingOptionsLimit;
    }

    if (isNaN(normalizedConfig.pickupLocationsLimit) || normalizedConfig.pickupLocationsLimit < 1 || normalizedConfig.pickupLocationsLimit > 99) {
        logInvalidParameterError('pickupLocationsLimit');
        normalizedConfig.pickupLocationsLimit = DEFAULT_CONFIG.pickupLocationsLimit;
    }

    return normalizedConfig;
};

const logInvalidParameterError = (fieldName) => {
    console.error(`Invalid parameter "${fieldName}" set to default value`);
};

const getNetwork = (config, isStorePickup) => {
    const isStoreTabEnabled = config.availableTabs.includes(TABS.STORE);

    if (!isStoreTabEnabled) {
        return NETWORK.ALL;
    }

    return isStorePickup ? NETWORK.STORE : NETWORK.CARRIER;
};
