import { Component } from 'preact';
import PostNumberHeaderForm from "./PostNumberHeaderForm";
import PostNumberInput from "./PostNumberInput";
import { checkout } from "../../service/restApiService";
import TextWrapper from "../../util/wrapper/TextWrapper";


const STATUS_LOADING = "STATUS_LOADING";
const STATUS_VALID = "VALID";
const STATUS_INVALID = "INVALID";


export default class PostNumberContainer extends Component {

    constructor(props) {
        super(props);

        let postNumber = props.config && props.config.customerNumber;
        let stringPostNumber = typeof postNumber === 'number' ? postNumber.toString() : postNumber;

        this.state = {
            postNumber:  stringPostNumber || '',
            status: null
        };
    }

    componentDidMount () {
        const {tabActive} = this.props;
        const {postNumber} = this.state;

        if (tabActive && postNumber) {
            this.onChangeConfirm();
        }
    }

    componentDidUpdate (prevProps) {
        const {selectedOption, tabActive} = this.props;
        const {postNumber} = this.state;

        const optionChanged = selectedOption && selectedOption.identifier !== prevProps.selectedOption.identifier;
        if (tabActive && postNumber && (optionChanged || tabActive !== prevProps.tabActive)) {
            this.onChangeConfirm();
        }
    }

    componentWillUnmount() {
        this.props.onChangeAccountNumber && this.props.onChangeAccountNumber(null, null);
    }

    onChangePostNumber = (postNumber) => {
        this.setState({postNumber});
    };

    onChangeConfirm = () => {
        const {config, selectedOption, pickupLocationCode, onChangeAccountNumber} = this.props;
        const {postNumber} = this.state;

        if (!this.isPostNumberValid()) {
            this.setState({status: STATUS_INVALID});
            onChangeAccountNumber(postNumber, false);
            return;
        }

        this.setState({status: STATUS_LOADING});
        checkout(config, selectedOption.identifier, pickupLocationCode, selectedOption.exactDay, postNumber, this.onCheckoutSuccess, this.onCheckoutError);

    };

    onCheckoutSuccess = res => {
        if (res.status === 200) {
            this.setState({status: STATUS_VALID});
            this.props.onChangeAccountNumber(this.state.postNumber, true);
        }
    };

    onCheckoutError = err => {
        if (err.response.status === 400 && err.response.data.errors && err.response.data.errors[0].code === 2110) {
            this.setState({status: STATUS_INVALID});
            this.props.onChangeAccountNumber(this.state.postNumber, false);
        }
    };

    isPostNumberValid = () => {
        const {selectedOption} = this.props;

        if (selectedOption.carrier.name !== "DHL_DE") {
            return true;
        }
        const {postNumber} = this.state;

        return postNumber && postNumber.match(/^\d{6,10}$/);
    };

    render() {
        const {postNumber, status} = this.state;
        const {selectedOption} = this.props;

        return (
            <aside className="postnumber">
                <PostNumberHeaderForm
                    selectedOption={selectedOption}/>

                <form className="postnumber__form">
                    <PostNumberInput postNumber={postNumber}
                                     selectedOption={selectedOption}
                                     onChange={this.onChangePostNumber}
                                     onChangeConfirm={this.onChangeConfirm}/>

                    {status === STATUS_LOADING && <figure className="postnumber__status postnumber__status--loading">Loading</figure>}
                    {status === STATUS_VALID && <figure className="postnumber__status postnumber__status--valid">Valid</figure>}
                    {status === STATUS_INVALID && <figure className="postnumber__status postnumber__status--invalid">Invalid</figure>}
                    {status === STATUS_INVALID && <div className="postnumber__status__message"><TextWrapper id="customerNumber.invalidCustomerNumber"/></div>}
                </form>
            </aside>
        );
    }
}
