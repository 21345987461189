import { Component } from 'preact';
import TextWrapper from "../../util/wrapper/TextWrapper";

export default class TitleForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header className="body__header">
                <TextWrapper Tag="h3"
                             htmlId="delivery-tab-title"
                             additionalClassName="body__title title"
                             id="shippingOptions.title"/>
            </header>
        );
    }
}