import { Component } from 'preact';
import DeliveryContainer from "./delivery/DeliveryContainer";
import PickupContainer from "./pickup/PickupContainer";
import { TABS } from "./constants/constants";

export default class BodyContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {config, selectedTab, onTabLoaded} = this.props;

        const deliveryActive = selectedTab === TABS.DELIVERY;
        const storeActive = selectedTab === TABS.STORE;
        const pickupActive = selectedTab === TABS.PICKUP;
        const parcelLockerActive = selectedTab === TABS.PARCEL_LOCKER;

        const tabs = {
            [TABS.DELIVERY]: <DeliveryContainer key={TABS.DELIVERY} tabActive={deliveryActive} config={config} onTabLoaded={onTabLoaded}/>,
            [TABS.STORE]: <PickupContainer key={TABS.STORE} tabActive={storeActive} config={config} onTabLoaded={onTabLoaded} isStorePickup/>,
            [TABS.PICKUP]: <PickupContainer key={TABS.PICKUP} tabActive={pickupActive} config={config} onTabLoaded={onTabLoaded}/>,
            [TABS.PARCEL_LOCKER]: <PickupContainer key={TABS.PARCEL_LOCKER} tabActive={parcelLockerActive} config={config} onTabLoaded={onTabLoaded} isParcelLocker/>
        };

        return (
            <section className="paazl__body body">
                <div className="body__panels panels method-select__panels">

                    {
                        config.availableTabs && config.availableTabs.map(t => tabs[t])
                    }

                </div>
            </section>
        );
    }
}