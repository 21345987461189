import { Component } from 'preact';
import ClassNames from "classnames";
import { DELIVERY_TYPES } from "../../constants/constants";
import TextWrapper from "../../util/wrapper/TextWrapper";
import { findMinPrice, formatRawPrice } from "../../util/CurrencyUtils";
import { getCurrentTranslation } from "../../../i18n/I18nResolver";

export default class TypeNavigation extends Component {

    constructor(props) {
        super(props);
    }

    showEstimatedDate = () => {
        const {onSelectType} = this.props;
        onSelectType(DELIVERY_TYPES.ESTIMATED_DATE);
    };

    showExactDate = () => {
        const {onSelectType} = this.props;
        onSelectType(DELIVERY_TYPES.EXACT_DATE);
    };

    render() {
        const {selectedType, exactDateOptions, estimatedDateOptions, config} = this.props;

        const isEstimatedDateSelected = DELIVERY_TYPES.ESTIMATED_DATE === selectedType;
        const isExactDateSelected = DELIVERY_TYPES.EXACT_DATE === selectedType;

        const estimatedDateClassName = ClassNames(
            "tabs__navigation__tab type-select__tab tab",
            {"tab--selected": isEstimatedDateSelected}
        );

        const exactDateClassName = ClassNames(
            "tabs__navigation__tab type-select__tab tab",
            {"tab--selected": isExactDateSelected}
        );

        const hasExactDateOptions = exactDateOptions && exactDateOptions.length > 0;
        const hasEstimatedDateOptions = estimatedDateOptions && estimatedDateOptions.length > 0;

        const navigationClassName = ClassNames(
            "tabs__navigation type-select__navigation",
            {"hidden": !hasExactDateOptions || !hasEstimatedDateOptions}
        );

        const translation = getCurrentTranslation();

        let estimatedDateMinPrice = findMinPrice(estimatedDateOptions);
        const estimatedDateLowestPriceTextId = translation.common.fromZeroValue && !estimatedDateMinPrice ? "common.fromZeroValue" : "common.fromValue";

        let exactDateMinPrice = findMinPrice(exactDateOptions);
        const exactDateLowestPriceTextId = translation.common.fromZeroValue && !exactDateMinPrice ? "common.fromZeroValue" : "common.fromValue";

        return (
            <nav className={navigationClassName}>
                <ul className="tabs__navigation__listing type-select__listing" role="presentation">
                    <li className="tabs__navigation__item type-select__item" role="presentation">
                        <button type="button"
                                className={estimatedDateClassName}
                                onClick={this.showEstimatedDate}
                                role="tab"
                                id="type-picker__btn--0"
                                aria-controls="type-picker__panel--0"
                                aria-selected={isEstimatedDateSelected}>

                            <span className="paazl-delimiter">
                                <TextWrapper id="tabs.estimatedDate" additionalClassName="tab__title"/>

                                {
                                    config.isPricingEnabled &&
                                    <span className="tab__date">
                                        <TextWrapper id={estimatedDateLowestPriceTextId}
                                                     fields={{lowestPrice: formatRawPrice(estimatedDateMinPrice, config)}}
                                                     capitalize/>
                                    </span>
                                }
                            </span>

                        </button>
                    </li>

                    <li className="tabs__navigation__item type-select__item" role="presentation">
                        <button type="button"
                                className={exactDateClassName}
                                onClick={this.showExactDate}
                                role="tab"
                                id="type-picker__btn--1"
                                aria-controls="type-picker__panel--1"
                                aria-selected={isExactDateSelected}>

                            <span className="paazl-delimiter">
                                <TextWrapper id="tabs.exactDate" additionalClassName="tab__title"/>

                                {
                                    config.isPricingEnabled &&
                                    <span className="tab__date">
                                        <TextWrapper id={exactDateLowestPriceTextId}
                                                     fields={{lowestPrice: formatRawPrice(exactDateMinPrice, config)}}
                                                     capitalize/>
                                    </span>
                                }
                            </span>

                        </button>
                    </li>
                </ul>
            </nav>
        );
    }
}