import { Component } from 'preact';
import { getCurrentTranslation } from "../../../../i18n/I18nResolver";
import StringWrapper from "../../../util/wrapper/StringWrapper";

export default class ShippingOptionsDropdown extends Component {

    constructor(props) {
        super(props);
    }

    onSelectShippingOption = (e) => {
        const {pickupLocation, onSelectShippingOption} = this.props;
        onSelectShippingOption && onSelectShippingOption(pickupLocation.code, e.target.value);
    };

    countDelta = (p) => {
        const {currentRate} = this.props;

        let delta;

        if (p.rate === 0) {
            let tranlations = getCurrentTranslation();
            delta = `(${tranlations.common.free})`;
        } else {
            let rate = p.rate - currentRate;
            let prefix = rate > 0 ? "+" : "";

            delta = rate === 0 ? "" : "(" + (prefix + "€ " + rate) + ")";
        }

        return <option value={p.identifier}>
            <StringWrapper content={`${p.name} ${delta}`} notranslate/>
        </option>;
    };

    render() {
        const {pickupLocation, setDropdownRef} = this.props;

        return (
            pickupLocation.shippingOptions.length > 1 &&

            <div className="point__select__dropdown select" ref={setDropdownRef}>
                <select className="select__source"
                        onChange={this.onSelectShippingOption}
                        data-no-fancy-select="true">
                    {
                        pickupLocation.shippingOptions.map(this.countDelta)
                    }
                </select>
            </div>
        );
    }
}