import { preparePickupLocationsRequest } from "../../components/util/ConfigUtils";
import { doPost } from "../../components/util/NetworkUtils";
import {
    SAVE_CHECKOUT_INFO_URL,
    SERVICE_POINT_LIST_URL,
    STORAGE_OPTION_KEY,
    STORAGE_PICKUP_CODE_KEY,
    STORAGE_PREFERRED_DATE_KEY
} from "../../components/constants/constants";
import { FORMAT_MASKS } from '../constants/constants';
import { format } from '../util/DateTimeUtils';
import { logRequestError, logRequestPerformed } from "../util/Logger";

export const loadPickupLocations = (config, isStorePickup, isParcelLocker, setPickupLocations) => {

    let request = preparePickupLocationsRequest(config, isStorePickup, isParcelLocker);

    setPickupLocations([], true);

    doPost(SERVICE_POINT_LIST_URL, request)
        .then(res => {
            logRequestPerformed(config, SERVICE_POINT_LIST_URL, request);

            const locations = res.data && res.data.pickupLocations || [];

            setPickupLocations(locations);
        })
        .catch((e) => {
            console.error(e);
            logRequestError(config, e, SERVICE_POINT_LIST_URL, request);

            setPickupLocations([]);
        });
};

export const checkout = (config, identifier, code, preferredDeliveryDate, accountNumber, onSuccess, onError) => {
    let body = {
        apiKey: config.apiKey,
        token: config.token,
        shippingOption: {
            identifier: identifier
        }
    };

    if (preferredDeliveryDate) {
        body = {...body, preferredDeliveryDate: format(preferredDeliveryDate, FORMAT_MASKS.DEFAULT_DATE, true)};
    }

    if (code) {
        body = {...body, pickupLocation: {...body.pickupLocation, code}};
    }

    if (accountNumber) {
        body = {...body, pickupLocation: {...body.pickupLocation, accountNumber}};
    }

    return doPost(SAVE_CHECKOUT_INFO_URL, body)
        .then((res) => {
            localStorage.setItem(STORAGE_OPTION_KEY, identifier);
            localStorage.setItem(STORAGE_PREFERRED_DATE_KEY, preferredDeliveryDate && format(preferredDeliveryDate, FORMAT_MASKS.DEFAULT_DATE, true));
            localStorage.setItem(STORAGE_PICKUP_CODE_KEY, code);

            logRequestPerformed(config, SAVE_CHECKOUT_INFO_URL, body);
            onSuccess && onSuccess(res);

            const {apiKey, token, ...selectedInfo} = body;
            config.onSelect && config.onSelect(selectedInfo);
        })
        .catch((e) => {
            console.error(e);
            logRequestError(config, e, SAVE_CHECKOUT_INFO_URL, body);
            onError && onError(e);
        });

};
