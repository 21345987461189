import { Component } from 'preact';
import OptionRowForm from "../form/OptionRowForm";
import ItemsNotFoundForm from "../../common/ItemsNotFoundForm";
import { TABS } from "./../../constants/constants";

export default class RawDateContainer extends Component {

    static defaultProps = {
        options: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.checkDefaultOption();
    }

    componentDidUpdate() {
        this.checkDefaultOptionOnUpdate();
    }

    checkDefaultOptionOnUpdate = () => {
        const {options, onSelectOption, exactDate} = this.props;

        if (options && options.length && options.findIndex(o => o.checked) === -1) {
            onSelectOption(options[0].identifier, exactDate);
        }
    };

    checkDefaultOption = () => {
        const {options, onSelectOption, exactDate} = this.props;

        if (options && options.length && options.findIndex(o => o.checked) === -1) {
            onSelectOption(options[0].identifier, exactDate);
        } else if (options && options.length && options.findIndex(o => o.checked) >= 0) {
            onSelectOption(options.find(o => o.checked).identifier, exactDate);
        }
    };

    render() {
        const {options, onSelectOption, exactDate, config} = this.props;

        const hasItems = options && options.length > 0;

        return (
            <form className="options">
                {!hasItems && <ItemsNotFoundForm tab={TABS.DELIVERY}/>}

                <ul className="options__listing" aria-labelledby="delivery-tab-title">
                    {
                        hasItems &&
                        options.map(o => <OptionRowForm option={o}
                                                        exactDate={exactDate}
                                                        onSelectOption={onSelectOption}
                                                        config={config}/>)
                    }
                </ul>
            </form>
        );
    }
}