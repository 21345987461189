import { Component } from 'preact';

export default class LightGreen extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                    /* Global */
                    --brand-global-radius: 2px;

                    /* Colors */
                    --brand-color-background: #FFF;
                    --brand-color-secondary: #F6F3EF;
                    --brand-color-border: #DAD6D2;
                    --brand-color-body: #525252;
                    --brand-color-primary: #99CC66;
                    --brand-color-focus: #FFCC00;

                    /* Fonts */
                    --brand-font-family-title: "Times New Roman", serif;
                    --brand-font-family-body: "";
                    --brand-font-weight-regular: 400;
                    --brand-font-weight-bold: 700;

                    /* Title */
                    --brand-title-weight: 400;
                    --brand-title-transform: uppercase;
                    --brand-title-spacing: 1px;

                    /* Input */
                    --brand-input-border-width: 0px;
                    --brand-input-border-color: transparent;

                    /* Button */
                    --brand-button-radius: 100px;
                    --brand-button-border-size: 0px;
                    --brand-button-border-color: transparent;
                    --brand-button-font-color: #FFF;
                    --brand-button-font-size: 14px;
                    --brand-button-font-weight: 400;
                    --brand-button-font-transform: uppercase;
                    --brand-button-font-spacing: 1px;
                  }
            `
            }}></style>

        );
    }
}