import { Component } from 'preact';
import TextWrapper from "../../../../components/util/wrapper/TextWrapper";

export default class SearchContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postalCode: "",
            consigneePostalCode: ""
        };
    }

    componentDidMount = () => {
        const {consigneePostalCode} = this.props.config;
        this.setState({postalCode: consigneePostalCode});
    };

    checkEnterKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.onChangePostalCode(e);
            this.executeUpdatePostalCode();
        }
    };

    onChangePostalCode = (e) => {
        const postalCode = e.target.value;
        this.setState({postalCode});
    };

    onSearchButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.executeUpdatePostalCode();
    };

    executeUpdatePostalCode = () => {
        const {consigneePostalCode, postalCode} = this.state;
        const {loadPickupLocations} = this.props;

        if (consigneePostalCode !== postalCode) {
            this.setState({consigneePostalCode: postalCode});
            loadPickupLocations(postalCode);
        }
    };

    render() {
        const {postalCode} = this.state;

        return (
            <form className="window__form">
                <div className="window__form__input-group">
                    <input type="text" className="window__form__field field field--search"
                           value={postalCode}
                           onChange={this.onChangePostalCode}
                           onBlurCapture={this.executeUpdatePostalCode}
                           onKeyPress={this.checkEnterKeyPress}/>

                    <button className="window__form__button button button--primary button--small"
                            onClick={this.onSearchButtonClick}>
                        <TextWrapper id="pickupLocations.search"/>
                    </button>
                </div>
            </form>
        );
    }
}