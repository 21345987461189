import {Component} from 'preact';
import StringWrapper from "../../util/wrapper/StringWrapper";
import {formatPrice, getPickupLocationShippingRate} from "../../util/CurrencyUtils";
import {formatDistance} from "../../util/ConversionUtils";

export default class LabelPriceDistanceAsideForm extends Component {

    constructor(props) {
        super(props);
    }

    getPrice = () => {
        const {config, pickupLocation} = this.props;

        const rate = getPickupLocationShippingRate(pickupLocation);

        return formatPrice(rate, config, "point__cost");
    };

    render() {
        const {pickupLocation} = this.props;

        const distance = formatDistance(pickupLocation.distance);

        return (
            <section className="point__aside">
                {this.getPrice()}

                {
                    distance &&
                    <StringWrapper content={distance}
                                   additionalClassName="point__distance"/>
                }
            </section>
        );
    }
}