import { Component } from 'preact';
import TextWrapper from "../util/wrapper/TextWrapper";
import { TABS } from "../constants/constants";

export default class ItemsNotFoundForm extends Component {

    constructor(props) {
        super(props);
    }

    getTranslationKey = () => {
        const {tab} = this.props;

        switch (tab) {
            case TABS.DELIVERY:
                return "shippingOptions.optionsNotFound";
            case TABS.STORE:
                return "pickupLocations.store.itemsNotFound";
            case TABS.PICKUP:
                return "pickupLocations.carrier.itemsNotFound";
            case TABS.PARCEL_LOCKER:
                return "pickupLocations.locker.itemsNotFound";
            default:
                return "";
        }
    };

    render() {
        return (
            <TextWrapper id={this.getTranslationKey()}/>
        );
    }
}