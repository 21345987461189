import { Component } from 'preact';
import ClassNames from "classnames";
import TextWrapper from "../util/wrapper/TextWrapper";
import { TAB_TYPES } from "../constants/constants";
import { formatRawPrice } from "../util/CurrencyUtils";
import StringWrapper from "../util/wrapper/StringWrapper";
import { getCurrentTranslation } from "../../i18n/I18nResolver";

export default class HeaderButton extends Component {

    constructor(props) {
        super(props);
    }

    onSelectTab = () => {
        const {tab, selectedTab, onChangeTab} = this.props;

        if (tab !== selectedTab) {
            onChangeTab && onChangeTab(tab);
        }
    };

    getIcon = () => {
        const {tab} = this.props;

        const icons = {
            DELIVERY: <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                <path fill-rule="nonzero"
                      d="M17.273 6.636h-2.728V3H1.818C.818 3 0 3.818 0 4.818v10h1.818a2.724 2.724 0 0 0 2.727 2.727 2.724 2.724 0 0 0 2.728-2.727h5.454a2.724 2.724 0 0 0 2.728 2.727 2.724 2.724 0 0 0 2.727-2.727H20v-4.545l-2.727-3.637zM4.545 16.182c-.754 0-1.363-.61-1.363-1.364 0-.754.609-1.363 1.363-1.363.755 0 1.364.609 1.364 1.363 0 .755-.609 1.364-1.364 1.364zM16.818 8l1.782 2.273h-4.055V8h2.273zm-1.363 8.182c-.755 0-1.364-.61-1.364-1.364 0-.754.609-1.363 1.364-1.363.754 0 1.363.609 1.363 1.363 0 .755-.609 1.364-1.363 1.364z"/>
            </svg>,

            STORE: <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                <path d="M18 2H2v2h16V2zm1 10v-2l-1-5H2l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H4v-4h6v4z"
                      fill-rule="nonzero"/>
            </svg>,

            PICKUP: <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                <path fill-rule="nonzero"
                      d="M16 6c0-3.31-2.69-6-6-6S4 2.69 4 6c0 4.5 6 11 6 11s6-6.5 6-11zM8 6c0-1.1.9-2 2-2s2 .9 2 2a2 2 0 1 1-4 0zM3 18v2h14v-2H3z"/>
            </svg>,

            PARCEL_LOCKER: <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                <path fill-rule="nonzero"
                      d="M16 6c0-3.31-2.69-6-6-6S4 2.69 4 6c0 4.5 6 11 6 11s6-6.5 6-11zM8 6c0-1.1.9-2 2-2s2 .9 2 2a2 2 0 1 1-4 0zM3 18v2h14v-2H3z"/>
            </svg>
        };

        return icons[tab];
    };

    getButtonContent = () => {
        const {tab, tabDetails, config} = this.props;

        if (config.headerTabType === TAB_TYPES.TAB) {
            return (
                <span>
                    {this.getIcon()}
                    <TextWrapper id={"tabs." + tab}/>
                </span>
            );
        }

        if (config.headerTabType === TAB_TYPES.BUTTON) {

            const price = tabDetails && tabDetails.price;
            const date = tabDetails && tabDetails.date;

            const translation = getCurrentTranslation();
            const lowestPriceTextId = translation.common.fromZeroValue && !price ? "common.fromZeroValue" : "common.fromValue";

            return (
                <span className="paazl-delimiter">
                    <figure className="method-select__button__icon">
                        {this.getIcon()}
                    </figure>

                    <TextWrapper id={"tabs." + tab} additionalClassName="tab__title"/>

                    {
                        config.isPricingEnabled && price !== undefined &&
                        <span className="tab__price">
                            <TextWrapper id={lowestPriceTextId}
                                         fields={{lowestPrice: formatRawPrice(price, config)}}
                                         capitalize/>
                        </span>
                    }

                    {date && <StringWrapper additionalClassName="tab__date" content={date} capitalize/>}
                </span>
            );
        }
    };

    render() {
        const {tab, selectedTab, config} = this.props;

        const tabSelected = tab === selectedTab;

        const tabClassName = ClassNames(
            "header__tab tab tab--header method-select__" + config.headerTabType.toLowerCase(),
            {"tab--selected": tabSelected}
        );

        return (
            <button className={tabClassName}
                    role="tab"
                    id={"tab-button-" + tab.toLowerCase()}
                    aria-controls={"tab-panel-" + tab.toLowerCase()}
                    aria-selected={tabSelected}
                    onClick={this.onSelectTab}>
                {this.getButtonContent()}
            </button>
        );
    }
}