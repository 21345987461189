import {Component} from 'preact';
import TextWrapper from "../../util/wrapper/TextWrapper";
import {getPickupType} from "../../util/AppUtils";

export default class TitleForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isStorePickup, isParcelLocker} = this.props;

        const pickupType = getPickupType(isStorePickup, isParcelLocker);

        return (
            <header className="body__header">
                <TextWrapper Tag="h3"
                             htmlId="pickup-tab-title"
                             additionalClassName="body__title title"
                             id={"pickupLocations." + pickupType + ".title"}/>
            </header>
        );
    }
}