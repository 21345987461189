import {
    BASE_URL,
    GIT_BRANCH,
    GIT_COMMIT_HASH,
    GIT_VERSION,
    GOOGLE_API_KEY,
    LOG_EVENT,
    LOG_LEVEL,
    RESOURCE_BASE_URL
} from '../constants/constants';
import {sendLogReport} from "./NetworkUtils";

const platform = require('platform');

export const logSystemInfo = (config) => {
    const {apiKey, apiSecret, ...widgetConfig} = config;

    const pageUrl = window.location.href;

    const systemInfo = {
        apiKey,
        widgetConfig,
        googleApiKey: GOOGLE_API_KEY,
        pageUrl,
        apiUrl: BASE_URL,
        resourceUrl: RESOURCE_BASE_URL,
        platform: platform.toString(),
        git: {
            version: GIT_VERSION,
            branch: GIT_BRANCH,
            commit: GIT_COMMIT_HASH
        }
    };

    console.log("Paazl checkout widget info: ", systemInfo);
};

export const logJsError = (config, error) => {
    if (config.logLevel !== LOG_LEVEL.NONE) {
        sendLogReport({
            logLevel: LOG_LEVEL.ERROR,
            logEvent: LOG_EVENT.JAVASCRIPT_ERROR,
            message: error,
            config
        });
    }
};

export const logRequestError = (config, error, url, requestBody) => {
    if (config.logLevel !== LOG_LEVEL.NONE) {
        sendLogReport({
            logLevel: LOG_LEVEL.ERROR,
            logEvent: LOG_EVENT.ENDPOINT_ERROR,
            message: `Request to ${url} has failed ${error}`,
            config,
            requestBody
        });
    }
};

export const logRequestPerformed = (config, url, requestBody) => {
    const {logLevel, apiKey} = config;

    if ([LOG_LEVEL.DEBUG, LOG_LEVEL.TRACE].includes(logLevel)) {
        let logConfiguration = {
            logLevel,
            logEvent: LOG_EVENT.ENDPOINT_EVENT,
            message: `Request to ${url} has been performed`,
            config: {apiKey}
        };

        if (logLevel === LOG_LEVEL.TRACE) {
            logConfiguration.config = config;
            logConfiguration.requestBody = requestBody;
        }

        sendLogReport(logConfiguration);
    }
};

export const logWidgetLoaded = (config) => {
    const {logLevel, apiKey} = config;

    if ([LOG_LEVEL.DEBUG, LOG_LEVEL.TRACE].includes(logLevel)) {
        let logConfiguration = {
            logLevel,
            logEvent: LOG_EVENT.LOADING_EVENT,
            message: "Widget has been rendered",
            config: {apiKey}
        };

        if (logLevel === LOG_LEVEL.TRACE) {
            logConfiguration.config = config;
        }

        sendLogReport(logConfiguration);
    }
};

export const logApiTriggered = (config, apiMethod) => {
    const {logLevel, apiKey} = config;

    if ([LOG_LEVEL.DEBUG, LOG_LEVEL.TRACE].includes(logLevel)) {
        let logConfiguration = {
            logLevel,
            logEvent: LOG_EVENT.WIDGET_API_EVENT,
            message: `Widget method ${apiMethod} has been invoked`,
            config: {apiKey}
        };

        if (logLevel === LOG_LEVEL.TRACE) {
            logConfiguration.config = config;
        }

        sendLogReport(logConfiguration);
    }
};
