import { Component } from 'preact';
import PickupModalListElement from "./PickupModalListElement";
import TextWrapper from "../../../util/wrapper/TextWrapper";
import ClassNames from "classnames";
import ItemsNotFoundForm from "../../../common/ItemsNotFoundForm";
import { getPickupTab } from "../../../../components/util/AppUtils";

export default class PickupModalListContainer extends Component {

    constructor(props) {
        super(props);
    }

    onPickupHere = () => {
        const {onPickupHere, pickupLocations, initialPickupLocations, config} = this.props;

        const itemIndex = pickupLocations.findIndex(o => o.checked);

        if (itemIndex !== -1) {
            const {initialPickupLocations: outerLocationsCount} = config;
            const itemVisibleInitially = itemIndex < outerLocationsCount && initialPickupLocations.length > 1;

            const visibleLocations = itemVisibleInitially ?
                pickupLocations.slice(0, outerLocationsCount) :
                pickupLocations.slice(itemIndex, itemIndex + 1);

            onPickupHere && onPickupHere(visibleLocations);
        }
    };

    preventScrollPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            pickupLocations,
            isStorePickup,
            isParcelLocker,
            storeLogo,
            loadMorePickupLocations,
            canLoadMore,
            createPickupLocationRef,
            onSelectPickupLocation,
            onSelectShippingOption,
            config
        } = this.props;

        const currentTab = getPickupTab(isStorePickup, isParcelLocker);

        const hasPickupLocations = pickupLocations && pickupLocations.length > 0;

        return (
            <section className="window__items"
                     onScroll={this.preventScrollPropagation}
                     onTouchMove={this.preventScrollPropagation}>
                <ul className="window__listing">

                    {
                        hasPickupLocations ?
                            pickupLocations.map(p => {
                                return <PickupModalListElement pickupLocation={p}
                                                               isStorePickup={isStorePickup}
                                                               isParcelLocker={isParcelLocker}
                                                               storeLogo={storeLogo}
                                                               onSelectPickupLocation={onSelectPickupLocation}
                                                               onSelectShippingOption={onSelectShippingOption}
                                                               onPickupHere={this.onPickupHere}
                                                               createPickupLocationRef={createPickupLocationRef}
                                                               config={config}/>;
                            }) :
                            <div className="service-points-not-found">
                                <ItemsNotFoundForm tab={currentTab}/>
                            </div>
                    }
                </ul>

                {
                    hasPickupLocations &&
                    <footer className={ClassNames("window__listing__footer", {"hidden": !canLoadMore})}>
                        <button className="window__listing__button button button--primary"
                                onClick={loadMorePickupLocations}>
                            <TextWrapper id="pickupLocations.loadMore"/>
                        </button>
                    </footer>
                }
            </section>
        );
    }
}