import {Component} from 'preact';
import TextWrapper from "../../util/wrapper/TextWrapper";
import StringWrapper from "../../util/wrapper/StringWrapper";
import ClassNames from "classnames";
import OpeningHoursTooltipLink from "../opening-hours/OpeningHoursTooltipLink";
import {resolveDateEstimationComponent, TYPE} from "../../util/DatesEstimationUtils";
import LabelAddressForm from "../form/LabelAddressForm";
import LabelPriceDistanceAsideForm from "../form/LabelPriceDistanceAsideForm";
import {findSelectedOptionForPickup, isStoreOption} from "../../util/AppUtils";
import StoreLogoForm from "../form/StoreLogoForm";
import CarrierLogoForm from "../form/CarrierLogoForm";
import {getCurrentTranslation} from "../../../i18n/I18nResolver";

export default class PickupElement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false,
            openingHoursVisible: false
        };
    }

    setInputRef = (node) => {
        this.inputRef = node;
    };

    onSelectPickupLocation = () => {
        const {pickupLocation, onSelectPickupLocation} = this.props;

        if (!pickupLocation.checked) {
            onSelectPickupLocation && onSelectPickupLocation(pickupLocation.code);
        }

        this.inputRef && this.inputRef.focus();
    };

    openMapModal = (e) => {
        e.preventDefault();
        this.props.toggleSimpleMapModal(true, this.props.pickupLocation);
    };

    getAvailabilityEstimate = () => {
        const {pickupLocation, config} = this.props;

        let shippingOption = findSelectedOptionForPickup(pickupLocation);

        if (shippingOption) {
            return resolveDateEstimationComponent(TYPE.PICKUP_SHORT, shippingOption, config.deliveryRangeFormat);
        }
    };

    onFocusIn = () => {
        this.setState({
            focused: true
        });
    };

    onFocusOut = () => {
        this.setState({
            focused: false
        });
    };

    getCustomerNumberDescription = () => {
        const {pickupLocation} = this.props;
        let translation = getCurrentTranslation();

        let shippingOption = findSelectedOptionForPickup(pickupLocation);
        let identifier = shippingOption.identifier;
        if (identifier.startsWith("ASENDIA_PICKPOST")) {
            return translation.customerNumber.pickPostDescription;
        }
        if (identifier.startsWith("ASENDIA_MY_POST_24")) {
            return translation.customerNumber.myPost24Description;
        }
        return translation.customerNumber.description;
    };

    render() {
        const {pickupLocation, isStorePickup, config, storeLogo, accountNumberWithState} = this.props;
        const {focused} = this.state;

        const selectedOption = findSelectedOptionForPickup(pickupLocation);
        const needsCustomerNumber = selectedOption && selectedOption.needsCustomerNumber;
        const accountNumberValid = accountNumberWithState && accountNumberWithState.accountNumber && accountNumberWithState.valid;

        const {checked} = pickupLocation;

        const isSentenceFormatReversed = ['chi', 'jpn'].includes(config.language.toLowerCase());
        const availabilityEstimate = this.getAvailabilityEstimate();

        const isStore = isStorePickup || isStoreOption(pickupLocation);

        const idPrefix = isStore ? "store-" : "point-";
        const itemClassName = isStore ? "store-select__item" : "pickup-select__item";

        return (
            <li className={itemClassName}>
                <article className={ClassNames("point", {"point--store": isStore})}
                         key={idPrefix + pickupLocation.code}
                         onClick={this.onSelectPickupLocation}>

                    <div className={ClassNames("point__label", {checked, focused})}>
                        <div className="paazl-delimiter">
                            <header className="point__header">
                                <section className={ClassNames("point__main", {"store-logo-missing": !storeLogo})}>
                                    <StoreLogoForm isStorePickup={isStore}
                                                   storeLogo={storeLogo}
                                                   config={config}/>

                                    <section className={ClassNames("point__main__body", {"store-logo-missing": !storeLogo})}>
                                        <h4 className="point__title">
                                            <input type="radio"
                                                   className="point__hidden"
                                                   id={idPrefix + pickupLocation.code}
                                                   name={isStore ? "selected-store" : "selected-point"}
                                                   ref={this.setInputRef}
                                                   checked={checked}
                                                   onFocus={this.onFocusIn}
                                                   onBlur={this.onFocusOut}/>

                                            <label htmlFor={idPrefix + pickupLocation.code}>
                                                <StringWrapper content={pickupLocation.name}
                                                               notranslate/>
                                            </label>
                                        </h4>
                                    </section>
                                </section>

                                <LabelPriceDistanceAsideForm pickupLocation={pickupLocation}
                                                             config={config}/>

                                {
                                    !isStore && <CarrierLogoForm config={config}
                                                                 shippingOption={pickupLocation.shippingOptions[0]}/>
                                }

                            </header>

                            {
                                checked && needsCustomerNumber && !accountNumberValid &&
                                <div className="point__alert">
                                    <span className="point__alert__message">{this.getCustomerNumberDescription()}</span>
                                </div>
                            }

                            <LabelAddressForm address={pickupLocation.address}
                                              storeLogoMissing={!storeLogo}/>

                            <footer className="point__footer">
                                <aside className={ClassNames({"point__footer__message": availabilityEstimate})}>
                                    {
                                        availabilityEstimate &&
                                        <TextWrapper id={"pickupLocations.available"}
                                                     contentBefore={isSentenceFormatReversed ? availabilityEstimate : ''}
                                                     contentAfter={isSentenceFormatReversed ? '' : availabilityEstimate}
                                                     spaceBetweenPrefix={isSentenceFormatReversed}
                                                     spaceBetweenSuffix={!isSentenceFormatReversed}/>
                                    }
                                </aside>

                                <ul className="point__options">
                                    <li className="point__options__item point__options__item--map">
                                        <button type="button"
                                                className="point__options__link link link--location"
                                                onClick={this.openMapModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M0 0h20v20H0z"/>
                                                    <path className="colorable" fill="#000" fill-rule="nonzero"
                                                          d="M15 6.66666667c0-2.75833334-2.2416667-5-5-5-2.75833333 0-5 2.24166666-5 5 0 3.75000003 5 9.16666663 5 9.16666663s5-5.4166666 5-9.16666663zm-6.66666667 0C8.33333333 5.75 9.08333333 5 10 5c.9166667 0 1.6666667.75 1.6666667 1.66666667 0 .91666666-.7416667 1.66666666-1.6666667 1.66666666-.91666667 0-1.66666667-.75-1.66666667-1.66666666zM4.16666667 16.6666667v1.6666666H15.8333333v-1.6666666H4.16666667z"/>
                                                </g>
                                            </svg>

                                            <TextWrapper id="pickupLocations.viewOnMap"/>
                                        </button>
                                    </li>

                                    <OpeningHoursTooltipLink openingTimes={pickupLocation.openingTimes}/>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </article>
            </li>
        );
    }
}