import { Component } from 'preact';
import ClassNames from "classnames";

export default class StoreLogoForm extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isStorePickup, storeLogo} = this.props;

        return (
            <section className={ClassNames({"point__main__logo": isStorePickup && storeLogo })}>
                {
                    isStorePickup && storeLogo &&
                    <figure className="point__logo">
                        <img src={storeLogo}
                             className="point__logo__source"/>
                    </figure>
                }
            </section>
        );
    }
}