import { Component } from 'preact';

export default class Loader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isLoading} = this.props;

        return (
            isLoading && <div className="paazl-delimiter loader"></div>
        );
    }
}