import fecha from 'fecha';
import {FORMAT_MASKS, TO_LOCAL_DATE_FORMATS} from '../constants/constants';

let i18n;
let language = "eng";

export const EXACT_DATE_CONTEXT = "EXACT_DATE_CONTEXT";
export const FROM_DATE_CONTEXT = "FROM_DATE_CONTEXT";
export const TO_DATE_CONTEXT = "TO_DATE_CONTEXT";

export const setUpDateFormats = (config) => {
    const maskConfig = {
        deliveryOptionDateFormat: config.deliveryOptionDateFormat,
        deliveryEstimateDateFormat: config.deliveryEstimateDateFormat,
        pickupOptionDateFormat: config.pickupOptionDateFormat,
        pickupEstimateDateFormat: config.pickupEstimateDateFormat
    };
    language = config.language || "eng";
    Object.keys(maskConfig).forEach(key => (maskConfig[key] === undefined) ? delete maskConfig[key] : '');
    fecha.masks =  maskConfig;
    fecha.setGlobalDateMasks(maskConfig);
};

export const internationalizeDates = (translations) => {
    const defaultI18n = {
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        amPm: ['am', 'pm'],
        // D is the day of the month, function returns something like...  3rd or 11th
        DoFn: (D)=> {
            return D + [ 'th', 'st', 'nd', 'rd' ][ D % 10 > 3 ? 0 : (D - D % 10 !== 10) * D % 10 ];
        }
    };

    i18n = translations.common.calendar;
    fecha.i18n = {...defaultI18n, ...translations.common.calendar};
    fecha.setGlobalDateI18n({...defaultI18n, ...translations.common.calendar});
};

export const parse = (dateString, format = FORMAT_MASKS.DEFAULT_DATE) => {
    return fecha.parse(dateString, format);
};

export const format = (date, format, useFormat) => {
    if (fecha.masks[format] || useFormat) {
        return fecha.format(date, format);
    }
    return date.toLocaleDateString(language, TO_LOCAL_DATE_FORMATS[format]);
};

export const formatMinMaxDays = ({min, max}) => {
    return `${min} - ${max} ` + i18n.days;
};

export const getDayName = (date) => {
    let today  = new Date();

    if (date.toDateString() === today.toDateString()) {
        return i18n.today;
    }

    let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

    if (date.toDateString() === tomorrow.toDateString()) {
        return i18n.tomorrow;
    }

    return fecha.format(date, FORMAT_MASKS.DAY_NAME);
};

export const convertDateString = (dateString, fromFormat, toFormat, context, alwaysFullDate) => {
    let date = parse(dateString, fromFormat);

    if (!alwaysFullDate) {
        let today  = new Date();

        if (date.toDateString() === today.toDateString()) {
            return i18n.today;
        }

        let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

        if (date.toDateString() === tomorrow.toDateString()) {
            return i18n.tomorrow;
        }
    }

    let formatted = format(date, toFormat);

    if (context) {
        return applyContext(formatted, context);
    }

    return formatted;
};

export const getDayOfWeek = (dayNumber) => {
    if (i18n) {
        return i18n.dayNames[dayNumber];
    }
};

const applyContext = (dateString, context) => {
    if (!i18n) {
        return dateString;
    }

    let dayIndex = -1;

    i18n.dayNames.forEach((e, i)=> {
        if (dateString.indexOf(e) !== -1) {
            dayIndex = i;
        }
    });

    if (context === EXACT_DATE_CONTEXT && dayIndex !== -1 && i18n.dayNamesExactDayContext) {
        return dateString.replace(i18n.dayNames[dayIndex], i18n.dayNamesExactDayContext[dayIndex]);
    }

    if (context === FROM_DATE_CONTEXT && dayIndex !== -1 && i18n.dayNamesFromDayContext) {
        return dateString.replace(i18n.dayNames[dayIndex], i18n.dayNamesFromDayContext[dayIndex]);
    }

    if (context === TO_DATE_CONTEXT && dayIndex !== -1 && i18n.dayNamesToDayContext) {
        return dateString.replace(i18n.dayNames[dayIndex], i18n.dayNamesToDayContext[dayIndex]);
    }

    return dateString;
};