import { Component } from 'preact';

export default class Default extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <style dangerouslySetInnerHTML={{
                __html: `
                :root {
                    /* Global */
                    --brand-global-radius: 0;
                    
                    /* Colors */
                    --brand-color-background: #FFFFFF;
                    --brand-color-secondary: #F6F6F6;
                    --brand-color-border: #C2C2C2;
                    --brand-color-body: #333333;
                    --brand-color-primary: #FF0000;
                    --brand-color-focus: #FFFF00;
                    
                    /* Fonts */
                    --brand-font-family-title: "Open Sans", sans-serif;
                    --brand-font-family-body: "Open Sans", sans-serif;
                    --brand-font-weight-regular: 300;
                    --brand-font-weight-bold: 700;
                    
                    /* Title */
                    --brand-title-weight: 600;
                    --brand-title-transform: normal;
                    --brand-title-spacing: 0;
                    
                    /* Input */
                    --brand-input-border-width: 1px;
                    --brand-input-border-color: #C2C2C2;
                    
                    /* Button */
                    --brand-button-radius: 0;
                    --brand-button-font-color: #FFFFFF;
                    --brand-button-font-size: 14px;
                    --brand-button-font-weight: 700;
                    --brand-button-font-transform: normal;
                    --brand-button-font-spacing: 1px;
                    --brand-button-method-background: #333333;
                    --brand-button-background: #FF0000;
                  }
            `
            }}></style>

        );
    }
}
