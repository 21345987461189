import { Component } from 'preact';
import ClassNames from "classnames";
import { DEFAULT_CONFIG, TAB_TYPES } from "../constants/constants";
import HeaderButton from "./HeaderButton";
import TabsDropdown from "../../components/header/TabsDropdown";

export default class Header extends Component {

    constructor(props) {
        super(props);
    }

    getAvailableTabs = () => {
        const {availableTabs} = this.props;

        if (availableTabs) {
            return availableTabs;
        }

        return DEFAULT_CONFIG.availableTabs;
    };

    render() {
        const {selectedTab, onChangeTab, config, tabDetails} = this.props;

        const tabs = this.getAvailableTabs();

        const isTab = config.headerTabType === TAB_TYPES.TAB;

        return (
            <header className={ClassNames("paazl__header header", {"has-select": isTab})}>
                <nav className="header__navigation method-select__navigation paazl-delimiter" role="tablist">
                    <TabsDropdown tabs={tabs}
                                  activeTab={selectedTab}
                                  onSelectTab={onChangeTab}/>

                    <div className={ClassNames("header__listing method-select__listing", "method-select__listing--" + config.headerTabType.toLowerCase())}>

                        {
                            tabs.map(t => <HeaderButton tab={t}
                                                        selectedTab={selectedTab}
                                                        tabDetails={tabDetails[t]}
                                                        config={config}
                                                        onChangeTab={onChangeTab}/>)
                        }

                    </div>
                </nav>
            </header>
        );
    }
}