import { Component } from 'preact';
import ClassNames from "classnames";
import PickupLocationMapForm from "../../pickup/modal/map/PickupLocationMapForm";

export default class SimpleMapModal extends Component {

    constructor(props) {
        super(props);
    }

    closeModal = () => {
        this.props.toggleMapModal(false);
    };

    preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    /**
     * Stub for unused callbacks.
     */
    noop = () => {};

    render() {
        const {pickupLocation, open, isStorePickup, storeLogo, config} = this.props;

        const modalClassName = ClassNames("pickup-select__modal modal", {open});
        const pickupLocations = pickupLocation ? [pickupLocation] : [];

        return (
            <aside className={modalClassName} aria-modal="true" aria-hidden="true" id="simple-map" onScroll={this.preventScroll} onTouchMove={this.preventScroll}>
                <div className="modal__container">
                    <section className="window">

                        <PickupLocationMapForm pickupLocations={pickupLocations}
                                               open={open}
                                               onSelectPickupLocation={this.noop}
                                               isStorePickup={isStorePickup}
                                               storeLogo={storeLogo}
                                               config={config}
                                               simple/>

                        <button className="window__close" onClick={this.closeModal}>
                            Close
                        </button>
                    </section>
                </div>
            </aside>
        );
    }
}