import { Component } from 'preact';
import ClassNames from "classnames";

export default class StringWrapper extends Component {

    static defaultProps = {
        Tag: 'span',
        notranslate: false,
        additionalClassName: "",
        spaceBefore: false,
        spaceAfter: false
    };

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate = (nextProps) => {
        return this.props.content !== nextProps.content ||
            this.props.contentBefore !== nextProps.contentBefore ||
            this.props.contentAfter !== nextProps.contentAfter;
    };

    render() {
        const {
            Tag,
            htmlId,
            content,
            notranslate,
            additionalClassName,
            spaceBefore,
            spaceAfter,
            contentBefore,
            contentAfter,
            spaceBetweenPrefix,
            spaceBetweenSuffix,
            capitalize
        } = this.props;

        return (
            <Tag id={htmlId} className={ClassNames(additionalClassName, {notranslate, capitalize})}>
                {spaceBefore && '\u00A0'}

                {contentBefore}

                {spaceBetweenPrefix && '\u00A0'}

                {content}

                {spaceBetweenSuffix && '\u00A0'}

                {contentAfter}

                {spaceAfter && '\u00A0'}
            </Tag>
        );
    }
}