import { Component } from 'preact';
import { STYLES } from "../constants/constants";
import Green from "./Green";
import LightGreen from "./LightGreen";
import Brown from "./Brown";
import Blue from "./Blue";
import Red from "./Red";
import Default from "./Default";
import Minimal from "./Minimal";

export default class StyleContainer extends Component {

    constructor(props) {
        super(props);
    }

    getStyle = (style) => {
        switch (style) {
            case STYLES.DEFAULT:
                return <Default/>;
            case STYLES.MINIMAL:
                return <Minimal/>;
            case STYLES.GREEN:
                return <Green/>;
            case STYLES.LIGHT_GREEN:
                return <LightGreen/>;
            case STYLES.BROWN:
                return <Brown/>;
            case STYLES.BLUE:
                return <Blue/>;
            case STYLES.RED:
                return <Red/>;
            default:
                return <Default/>;
        }
    };

    render() {
        const {style} = this.props;

        return (
            <div>
                {this.getStyle(style)}
            </div>
        );
    }
}