import { Component } from 'preact';
import TextWrapper from "../../util/wrapper/TextWrapper";
import { getDayOfWeek } from "../../util/DateTimeUtils";
import { getCurrentTranslation } from "../../../i18n/I18nResolver";
import OpeningHoursRow from "./OpeningHoursRow";

export default class OpeningHoursContainer extends Component {

    constructor(props) {
        super(props);
    }

    getBusinessHoursInWeek() {
        const {openingTimes} = this.props;

        const translation = getCurrentTranslation();

        let closed = translation.pickupLocations.closed;
        let unknown = translation.common.unknown;
        let alwaysAvailable = translation.pickupLocations.alwaysAvailable;

        let week = {
            MONDAY: {day: getDayOfWeek(1), time: {firstPart: closed}},
            TUESDAY: {day: getDayOfWeek(2), time: {firstPart: closed}},
            WEDNESDAY: {day: getDayOfWeek(3), time: {firstPart: closed}},
            THURSDAY: {day: getDayOfWeek(4), time: {firstPart: closed}},
            FRIDAY: {day: getDayOfWeek(5), time: {firstPart: closed}},
            SATURDAY: {day: getDayOfWeek(6), time: {firstPart: closed}},
            SUNDAY: {day: getDayOfWeek(0), time: {firstPart: closed}}
        };

        openingTimes.map(day => {
            let entry = week[day.day];
            let time = unknown;
            if (day.alwaysOpen === true) {
                time = {firstPart: alwaysAvailable};
            } else {
                time = this.calculateTime(day.businessHours);
            }
            week[day.day] = {...entry, time: time};
        });

        return week;
    }

    calculateTime = (businessHours) => {
        let closed = getCurrentTranslation().pickupLocations.closed;

        if (businessHours[0] && businessHours[1] && businessHours[0].to && businessHours[1].from
            && businessHours[0].to === businessHours[1].from) {
            return {firstPart: this.convertOpeningDate(businessHours[0].from, businessHours[1].to)};
        }

        let firstPart = businessHours[0] && this.convertOpeningDate(businessHours[0].from, businessHours[0].to);
        let secondPart = businessHours[1] && this.convertOpeningDate(businessHours[1].from, businessHours[1].to);

        if (firstPart && secondPart) {
            return {firstPart: firstPart + `\u00A0\u00A0\u00A0`, secondPart: secondPart};
        } else if (firstPart && !secondPart) {
            return {firstPart: firstPart};
        } else if (!firstPart && secondPart) {
            return {secondPart: secondPart};
        }

        return {firstPart: closed};
    };

    convertOpeningDate = (dateFrom, dateTill) => {
        let unknown = getCurrentTranslation().common.unknown;

        if (dateFrom && dateTill) {
            return `${dateFrom} - ${dateTill}`;
        } else if (!dateFrom && dateTill) {
            return `${unknown} - ${dateTill}`;
        } else if (dateFrom && !dateTill) {
            return `${dateFrom} - ${unknown}`;
        }
        return null;
    };

    render() {
        const openingHours = this.getBusinessHoursInWeek();

        return (
            <div className="paazl-delimiter">
                <TextWrapper Tag="h5"
                             id="pickupLocations.openingHours"
                             additionalClassName="tooltip__listing__title"/>

                <ul className="tooltip__listing">
                    {
                        openingHours && Object.keys(openingHours).map((key) => {
                            let timeFirstPart = openingHours[key].time.firstPart;
                            let timeSecondPart = openingHours[key].time.secondPart;

                            return <OpeningHoursRow dayOfWeek={openingHours[key].day}
                                                    timeFirstPart={timeFirstPart}
                                                    timeSecondPart={timeSecondPart}/>;
                        })
                    }
                </ul>
            </div>
        );
    }
}