import { Component } from 'preact';
import ClassNames from "classnames";
import { format, getDayName } from "../../util/DateTimeUtils";
import { FORMAT_MASKS } from "../../constants/constants";
import StringWrapper from "../../util/wrapper/StringWrapper";

const $ = window.jQuery;

export default class SelectDayCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canScrollLeft: false,
            canScrollRight: false
        };
    }

    componentDidMount = () => {
        window.addEventListener("resize", () => {
            this.updateDaySelectCompact();
        });

        this.updateDaySelectCompact();
    };

    updateDaySelectCompact() {
        const daySelectWidth = $('.day-select__navigation').width();
        const containerSize = 425; // 425px is minimal size to correct displaying styles on the day select carousel
        const daySelectCompact = daySelectWidth < containerSize;

        const visibleTabs = daySelectCompact ? 3 : 4;
        const canScrollRight = this.props.optionGroups && this.props.optionGroups.length > visibleTabs;

        this.setState({
            daySelectCompact,
            canScrollRight
        });
    }

    scrollLeft = () => {
        let checkButtons = this.checkButtons;
        let $container = $('.day-select__navigation');
        let currentScroll = $container.scrollLeft();
        let width = $container.width();

        $container.animate(
            {
                scrollLeft: currentScroll - width
            },
            {
                duration: 200,
                complete: function() {
                    checkButtons();
                }
            }
        );
    };

    scrollRight = () => {
        let checkButtons = this.checkButtons;
        let $container = $('.day-select__navigation');
        let currentScroll = $container.scrollLeft();
        let width = $container.width();

        $container.animate(
            {
                scrollLeft: currentScroll + width
            },
            {
                duration: 200,
                complete: function() {
                    checkButtons();
                }
            }
        );
    };

    checkButtons = () => {
        const {canScrollLeft, canScrollRight} = this.state;

        let $container = $('.day-select__navigation');
        let $listing = $('.day-select__listing');
        let innerWidth = $listing[0].scrollWidth;
        let width = $container.width();
        let margin = 10;
        let maxScroll = innerWidth - width - margin;
        let currentScroll = $container.scrollLeft();

        if (currentScroll <= 0) {
            this.setState({
                canScrollLeft: false
            });
        }

        if (currentScroll > 0 && !canScrollLeft) {
            this.setState({
                canScrollLeft: true
            });
        }

        if (currentScroll >= maxScroll) {
            this.setState({
                canScrollRight: false
            });
        }

        if (currentScroll < maxScroll && !canScrollRight) {
            this.setState({
                canScrollRight: true
            });
        }
    };

    render() {
        const {optionGroups, activeGroup, onSelectGroup} = this.props;
        const {canScrollLeft, canScrollRight, daySelectCompact} = this.state;

        return (
            <section className="day-select__slider" role="region" aria-label="Select a day">
                <button
                    className={ClassNames("day-select__slide day-select__slide--previous", {disabled: !canScrollLeft})}
                    aria-label="previous"
                    aria-controls="carousel"
                    onClick={this.scrollLeft}
                    disabled={!canScrollLeft}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" aria-hidden="true" focusable="false">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M20 20H0V0h20z"/>
                            <path className="colorable" fill="#000" fill-rule="nonzero"
                                  d="M11.667 15l1.175-1.175L9.025 10l3.817-3.825L11.667 5l-5 5z"/>
                        </g>
                    </svg>
                    Previous
                </button>

                <nav onScroll={this.checkButtons}
                     className="tabs__navigation day-select__navigation"
                     aria-label="carousel">
                    <ul className="tabs__navigation__listing day-select__listing"
                        role="presentation">

                        {
                            optionGroups && optionGroups.map((group, index) => {
                                let tabSelected = index === activeGroup;
                                let tabButtonClassName = ClassNames("tabs__navigation__tab day-select__tab tab", {"tab--selected": tabSelected});
                                let onClick = () => onSelectGroup(index);

                                let liClassName = ClassNames("tabs__navigation__item",
                                    {"day-select__item": !daySelectCompact},
                                    {"day-select__item-compact": daySelectCompact}
                                );

                                return (
                                    <li className={liClassName} role="presentation">
                                        <button type="button"
                                                role="tab"
                                                id={"day-picker__btn--" + index}
                                                aria-controls={"day-picker__panel--" + index}
                                                aria-selected={tabSelected}
                                                className={tabButtonClassName}
                                                onClick={onClick}>
                                            <span className="paazl-delimiter">
                                                <StringWrapper additionalClassName="tab__title"
                                                               content={getDayName(group.date)}/>
                                                <StringWrapper additionalClassName="tab__date"
                                                               content={format(group.date, FORMAT_MASKS.DAY_MONTH)}/>
                                            </span>
                                        </button>
                                    </li>
                                );
                            })
                        }

                    </ul>
                </nav>

                <button className={ClassNames("day-select__slide day-select__slide--next", {disabled: !canScrollRight})}
                        aria-label="next"
                        aria-controls="carousel"
                        onClick={this.scrollRight}
                        disabled={!canScrollRight}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" aria-hidden="true" focusable="false">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h20v20H0z"/>
                            <path className="colorable" fill="#000" fill-rule="nonzero"
                                  d="M8.333 5L7.158 6.175 10.975 10l-3.817 3.825L8.333 15l5-5z"/>
                        </g>
                    </svg>
                    Next
                </button>
            </section>
        );
    }
}